import React, { Fragment } from 'react';

import { Icon } from '@iconify/react';
import downloadOutline from '@iconify-icons/mdi/download-outline';

import { ISharingHeaderProps } from './types';
import './index.scss';

export const SharingHeader = ({ fileName, fileDataUrl, onlyLogo = false }: ISharingHeaderProps) => {
  const renderData = () => {
    if (onlyLogo) return null;
    return (
      <Fragment>
        <div className="sharing-header__name">
          {fileName}
        </div>
        <a
          href={fileDataUrl}
          className="sharing-header__download"
          data-testid="sharing-download-link"
          download
        >
          <Icon icon={downloadOutline} />
        </a>
      </Fragment>
    );
  };

  return (
    <div className="sharing-header">
      <div className="sharing-header__logo">
        <img src="/static/logo_white.png" alt="" />
      </div>
      {renderData()}
    </div>
  );
};
