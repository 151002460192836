import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { MainFiles } from './MainFiles';
import { ItemModal } from '../ItemModal';
import { MainListHeader } from './MainListHeader';
import { Placeholder } from '../common/Placeholder';
import { MainScreenTopPanel } from './MainScreenTopPanel';
import MouseBorderSelect from '../common/MouseBorderSelect';

import sendGtmTrigger from '../../utils/sendGtmTrigger';
import {
  selectSelectedFileIndexes,
  selectFile,
  selectFileWithShift,
  selectFileWithCtrl,
  selectFileClear,
} from '../../redusers/files/selected';
import { selectCtxModalIsOpen } from '../../redusers/ctxModal';
import { openCtxModal } from '../../redusers/ctxModal';

import { fileModalOpen } from '../../redusers/fileModal';
import isMobile from '../../utils/isMobile';
import { FILE_TYPE } from '../../constants/elementsTypes';
import { ModalTypes } from '../../types/modal.types';

import LongTouchProvider from '../../providers/longTouchProvider';

import './index.scss';

// компонент навешивает все события на область
const MainScreen = ({
  dispatch,
  noContent,
  isSelecting,
  withCtx,
}) => {
  const { pathname, search } = useLocation();
  const [isBlock, setIsBlock] = useState(false);
  const [firstSelectId, setFirstSelectId] = useState(null);
  const containerRef = useRef();
  const selectedFilesIndexes = useSelector(selectSelectedFileIndexes);
  const ctxModalIsOpen = useSelector(selectCtxModalIsOpen);

  const handleFileOpenEvent = (itemIdx) => {
    dispatch(push({ pathname, search, hash: '#viewer' }));
    dispatch(fileModalOpen(parseInt(itemIdx)));
  };

  const handleFileSelectEvent = (itemIdx, shiftKey, ctrlKey) => {
    const idx = parseInt(itemIdx);

    if (isMobile() && !withCtx) return dispatch(selectFileWithCtrl(idx));
    if (shiftKey) {
      sendGtmTrigger('sv2_select_files_shift');
      return dispatch(selectFileWithShift(idx));
    }
    if (ctrlKey) {
      sendGtmTrigger('sv2_select_files_ctrl');
      return dispatch(selectFileWithCtrl(idx));
    }
    return dispatch(selectFile(idx));
  };

  const handleOpenEvent = (e) => {
    const currItem = e.nativeEvent.composedPath().find((element) => element?.dataset?.itemType);

    if (currItem?.dataset?.itemType === FILE_TYPE) {
      return handleFileOpenEvent(currItem?.dataset?.itemIdx);
    }

    const isCtxMenu = e.nativeEvent.composedPath().find((element) => element?.id === 'context-menu-root');

    if (!isCtxMenu) {
      return dispatch(selectFileClear());
    }
  };

  // фик на старые версии iOs
  // блокируем эвент, тк там воспроизводится событие клика при лог табе
  const blockEven = (itemId) => {
    if (!isSelecting && !isBlock) {
      setIsBlock(true);
      setFirstSelectId(itemId);

      // очищать таймаут в useEffect
      setTimeout(() => {
        setIsBlock(false);
        setFirstSelectId(null);
      }, 900);
    }
  };

  const handleSelectEvent = (e, patch) => {
    // patch - присутствует только в логтапах для мобильных устрйоств
    // в остальнызх случаях вычисляем
    if (isBlock && isMobile()) return;
    if (isMobile()) blockEven();
    const currPatch = patch || e.nativeEvent.composedPath();
    const currItem = currPatch.find((element) => element?.dataset?.itemType);

    if (isBlock && currItem?.dataset?.itemId === firstSelectId) return;
    blockEven(currItem?.dataset?.itemId);

    sendGtmTrigger('sv2_select_files_frame');

    if (currItem?.dataset?.itemType === FILE_TYPE && currItem?.dataset?.withoutSelect !== 'true') {
      return handleFileSelectEvent(
        currItem?.dataset?.itemIdx,
        e.shiftKey,
        e.ctrlKey || e.metaKey
      );
    }

    const isCtxMenu = currPatch.find((element) => element?.id === 'context-menu-root');

    if (!isCtxMenu) {
      return dispatch(selectFileClear());
    }
  };

  const handleMobileContextMenu = (e) => {
    e.preventDefault();
  };

  const handleKeyUp = useCallback((e) => {
    if (
      (e.key === 'Backspace' || e.key === 'Delete')
      && e.target.localName !== 'input'
      && selectedFilesIndexes.length > 0
      && !ctxModalIsOpen
    ) {
      dispatch(openCtxModal({
        modalType: ModalTypes.CTX_MODAL_REMOVE,
        isMany: true,
      }));
    }
  }, [dispatch, ctxModalIsOpen, selectedFilesIndexes]);

  const renderContent = () => {
    if (noContent) {
      return (
        <Fragment>
          <MainScreenTopPanel />
          <Placeholder />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <MainListHeader />
        <MainScreenTopPanel />
        <MainFiles />
      </Fragment>
    );
  };

  const longTouchProvider = new LongTouchProvider(handleSelectEvent);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);

    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  return (
    <div
      className="main-screen"
      onDoubleClick={isMobile() ? undefined : handleOpenEvent}
      onContextMenu={handleMobileContextMenu}
      onClick={isMobile() && (!isSelecting || withCtx) ? handleOpenEvent : handleSelectEvent}
      onTouchStart={isMobile() ? longTouchProvider.touchstart : undefined}
      onTouchEnd={isMobile() ? longTouchProvider.touchend : undefined}
      ref={containerRef}
    >
      <div className="container">
        {renderContent()}
        <ItemModal />
        <MouseBorderSelect ref={containerRef} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ files, global }) => ({
  isSelecting: !!files.selected.indexes.length,
  withCtx: !!files.selected.withCtx,
  isList: global.view.isList,
  noContent: !files.list.data.length && !files.list.loading,
});

export default connect(mapStateToProps)(MainScreen);
