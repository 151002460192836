import React from 'react';
import classNames from 'classnames';

import { TopHeader } from 'components/TopHeader';
import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';
import { IStaticPageLayoutProps } from './staticPageLayout.types';

export const StaticPageLayout = ({
  children,
  className,
  globalClassName,
  logoWithoutLink = true,
}: IStaticPageLayoutProps) => {
  const layoutClasses = classNames('static-layouts', className);

  return (
    <div className={globalClassName}>
      <TopHeader hiddenSearch hiddenAvatar logoWithoutLink={logoWithoutLink} />
      <div className={layoutClasses}>
        {children}
      </div>
      <DownloadManagerContainer />
    </div>
  );
};
