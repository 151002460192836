import firebase from 'firebase/app';
import 'firebase/auth';

import { languageProvider } from './languageProvider';

export class FirebaseProvider {
  private recaptcha: firebase.auth.RecaptchaVerifier | null = null;

  private phoneConfirmationResult: firebase.auth.ConfirmationResult | null = null;

  private googleProvider: any;

  private facebookProvider: any;

  private appleProvider: any;

  private firebaseConfig: { [key: string]: string | undefined } = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  constructor() {
    firebase.initializeApp(this.firebaseConfig);
    firebase.auth().languageCode = languageProvider.getLanguageKey();
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
    this.googleProvider.setCustomParameters({ prompt: 'select_account' });
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.appleProvider = new firebase.auth.OAuthProvider('apple.com');
    this.appleProvider.addScope('email');
    this.appleProvider.addScope('name');
  }

  googleAuth = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.googleProvider);
      if (!result.user) throw Error('[FirebaseProvider Google]: User Not Found');

      return await result.user.getIdToken();
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  initRecaptha = (btnId: string) => {
    this.recaptcha = new firebase.auth.RecaptchaVerifier(
      btnId,
      { size: 'invisible' },
    );
  };

  phoneAuth = async (phoneNumber: string) => {
    if (!this.recaptcha) throw Error('[FirebaseProvider]: Recaptha is not initialized');
    if (!phoneNumber) throw Error('[FirebaseProvider]: phoneNumber is empty');
    try {
      this.phoneConfirmationResult = await firebase.auth().signInWithPhoneNumber(phoneNumber, this.recaptcha);
      
      // @ts-ignore
      if (this.recaptcha) this.recaptcha.reset();
    } catch (e) {
      this.phoneConfirmationResult = null;

      // @ts-ignore
      if (this.recaptcha) this.recaptcha.reset();
      throw e;
    }
  };

  confirmPhoneAuth = async (smsCode: string) => {
    try {
      if (!this.phoneConfirmationResult) throw Error('[FirebaseProvider Phone]: phoneConfirmationResult Not Found');
      const result = await this.phoneConfirmationResult.confirm(smsCode);
      if (!result.user) throw Error('[FirebaseProvider Phone]: User Not Found');

      return await result.user.getIdToken();
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  facebookAuth = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.facebookProvider);
      if (!result.user) throw Error('[FirebaseProvider Facebook]: User Not Found');

      return await result.user.getIdToken();
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  appleAuth = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.appleProvider);
      if (!result.user) throw Error('[FirebaseProvider Apple]: User Not Found');

      return await result.user.getIdToken();
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  singOut = async () => {
    try {
      return await firebase.auth().signOut();
    } catch (e) {
      console.log(e);
      return e;
    }
  };
}
export const firebaseProvider = new FirebaseProvider();
