import React, { Fragment, useState, ChangeEvent } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import bxGlobe from '@iconify-icons/bx/bx-globe';

import { Languages } from 'types/language.types';
import { SettingsLanguageItem } from './settingsLanguageItem';

import './settingsLanguage.scss';

export const SettingsLanguage = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const handleFocus = () => setIsFocus(true);
  const handleBlur = () => setIsFocus(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const searchClasses = classNames('custom-modal-languages__search', {
    'custom-modal-languages__search--focus': isFocus,
  });

  const languages = Languages.filter((language) => {
    return language.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      || (language.translate && language.translate.toLowerCase().indexOf(search.toLowerCase()) >= 0);
  });

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={bxGlobe} className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('settings__language')}
        </div>
      </Modal.Header>
      <Modal.Body className="custom-modal-languages">
        <div className={searchClasses}>
          <input
            onFocus={handleFocus}
            onBlur={handleBlur}
            type="text"
            className="form-control form-control-lg custom-modal-languages__input"
            placeholder={t('search-language__placeholder')}
            onChange={handleChange}
            value={search}
          />
        </div>
        <div className="custom-modal-languages__box">
          {languages.map((language) => (
            <SettingsLanguageItem key={language.key} language={language} />
          ))}
        </div>
      </Modal.Body>
    </Fragment>
  );
};
