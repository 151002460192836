import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Icon } from '@iconify/react';
import block from '@iconify-icons/mdi/block-helper';

export const BlockedAccount = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={block} className="custom-modal__icon custom-modal__icon--red" />
        <div className="custom-modal__title">
          {t('context-modal__blocked-title')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <Trans
          i18nKey="context-modal__blocked-text"
          components={[<a href="/support"></a>]}
        />
      </Modal.Body>
    </Fragment>
  );
};
