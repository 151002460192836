import React, { useRef } from 'react';

import { Icon } from '@iconify/react';
import arrowLeft from '@iconify-icons/mdi/arrow-left';
import dotsVertical from '@iconify-icons/mdi/dots-vertical';
import downloadOutline from '@iconify-icons/mdi/download-outline';

import ContextMenu from '../common/ContextMenu';
import './itemModalControl.scss';
import { FILE_TYPE } from '../../constants/elementsTypes';
import { IItemModalControl } from './types';

export const ItemModalControl = ({ file, handleClose, fileIndex, fromSearch = false }: IItemModalControl) => {
  const ctxBtnRef = useRef<HTMLDivElement>(null);

  return (
    <div className="item-modal-control">
      <div className="item-modal-control__close" onClick={handleClose}>
        <Icon icon={arrowLeft} />
      </div>
      <div className="item-modal-control__name">
        {file.name}
      </div>
      <div className="item-modal-control__ctx-box">
        <a href={file.data_url} className="item-modal-control__download" download>
          <Icon icon={downloadOutline} />
        </a>
        <div
          ref={ctxBtnRef}
          className="item-modal-control__ctx"
          data-context-active="true"
          data-item-type={FILE_TYPE}
          data-item-id={file.id}
          data-item-idx={fileIndex}
          data-item-share={file.share_url || false}
          data-item-url={file.data_url || false}
          data-item-name={file.name}
          data-item-from-search={fromSearch}
        >
          <Icon icon={dotsVertical} />
        </div>
      </div>
      {/* @ts-ignore */}
      <ContextMenu height={360} ref={ctxBtnRef} onlyByClick />
    </div>
  );
};
