import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import bxGlobe from '@iconify-icons/bx/bx-globe';
import cheveronRight from '@iconify-icons/zondicons/cheveron-right';

import { languageProvider } from 'providers/languageProvider';
import { openCtxModal } from 'redusers/ctxModal';
import { ModalTypes } from 'types/modal.types';
import { ISettingsLanguageComponentProps } from './types';

export const SettingsMenuLanguageComponent = ({ handleClick }: ISettingsLanguageComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="settings-menu__item" onClick={handleClick}>
        <div className="settings-menu__item-type">
          <Icon icon={bxGlobe} className="settings-menu__item-icon" />
          <span className="settings-menu__item-name">{t('settings__language')}</span>
        </div>
        <div className="settings-menu__item-meta">
          <span className="settings-menu__item-meta-info">
            {languageProvider.getLanguageName()}
            {languageProvider.getTranslateLanguageName() ? ` (${languageProvider.getTranslateLanguageName()})` : ''}
          </span>
          <Icon icon={cheveronRight} className="settings-menu__item-cheveron" />
        </div>
      </div>
    </Fragment>
  );
};

export const SettingsMenuLanguage = () => {
  const dispatch = useDispatch();

  const handleClick = () => dispatch(openCtxModal({
    modalType: ModalTypes.CTX_MODAL_SETTINGS_LANGUAGE,
  }));

  return <SettingsMenuLanguageComponent handleClick={handleClick} />;
};
