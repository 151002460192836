import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

import { Icon } from '@iconify/react';
import fileCancelOutline from '@iconify-icons/mdi/file-cancel-outline';

import {
  shareFileFetchingStart,
  selectSharingLoading,
  selectSharingSuccessStatus,
  selectSharingFileData,
} from 'redusers/sharing';

import { SharingLayout } from 'components/layouts/SharingLayout';
import { SharingHeader } from 'components/SharingHeader';
import { VideoViewer } from 'components/viewers/VideoViewer';
import { PhotoViewer } from 'components/viewers/PhotoViewer';
import { Spinner } from 'components/common/Spinner';

import { ITEM_VIDEO } from 'constants/elementsTypes';
import { ISharingPageComponentProps } from './types';
import './index.scss';

export const SharingPageComponent = ({ loading, success, file }: ISharingPageComponentProps) => {
  const { href } = window.location;

  const { t } = useTranslation();

  if (loading) {
    return (
      <SharingLayout className="sharing-page">
        <Spinner className="sharing-page__spinner" />
      </SharingLayout>
    );
  }

  if (!success || !file) {
    return (
      <SharingLayout className="sharing-page">
        <SharingHeader onlyLogo />
        <div className="sharing-page__wrap sharing-page__wrap--empty">
          <Icon className="sharing-page__empty-icon" icon={fileCancelOutline} />
          <div className="sharing-page__placeholder">
            {t('sharing__no-available')}
          </div>
        </div>
      </SharingLayout>
    );
  }

  return (
    <SharingLayout className="sharing-page">
      <Helmet>
        <meta property="og:title" content={`Stream Vision 2 - ${file.name}`} />
        <meta property="og:description" content={`Look at the Stream Vision file: ${file.name}`} />
        <meta property="og:image" content={file.preview_url} />
        <meta property="og:sitename" content="Stream Vision 2" />
        <meta property="og:url" content={href} />
      </Helmet>
      <SharingHeader fileName={file.name} fileDataUrl={file.data_url} />
      <div className="sharing-page__wrap">
        {
          file.metadata.type === ITEM_VIDEO
            ? <VideoViewer file={file} />
            : <PhotoViewer file={file} />
        }
      </div>
    </SharingLayout>
  );
};

export const SharingPage = () => {
  const { shareKey } = useParams<{ shareKey: string }>();

  const dispatch = useDispatch();
  const loading = useSelector(selectSharingLoading);
  const success = useSelector(selectSharingSuccessStatus);
  const file = useSelector(selectSharingFileData);

  useEffect(() => {
    dispatch(shareFileFetchingStart({ shareKey }));
  }, [dispatch, shareKey]);

  const props = { loading, success, file };

  return <SharingPageComponent {...props} />;
};
