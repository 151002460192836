import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { sortUpdate, selectSortFieldName, selectSortAsc, SortFields } from 'redusers/sort';
import { selectIsList } from 'redusers/global/view';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { IMainListHeaderComponentProps } from './types';
import './mainListHeader.scss';

export const MainListHeaderComponent = ({
  isList,
  sortFieldName,
  asc,
  handleNameSort,
  handleDateSort,
}: IMainListHeaderComponentProps) => {
  const { t } = useTranslation();

  if (!isList) return null;

  const nameColClasses = classNames('main-list-header__sortable', {
    'main-list-header__sortable--asc': sortFieldName === SortFields.name && asc,
    'main-list-header__sortable--desc': sortFieldName === SortFields.name && !asc,
  });

  const dateColClasses = classNames('main-list-header__sortable', {
    'main-list-header__sortable--asc': sortFieldName === SortFields.date && asc,
    'main-list-header__sortable--desc': sortFieldName === SortFields.date && !asc,
  });

  return (
    <div className="main-list-header">
      <div className="main-list-header__icon" />
      <div className="main-list-header__wrap">
        <div className="main-list-header__name" onClick={handleNameSort}>
          <span className={nameColClasses}>
            {t('main-list__name')}
          </span>
        </div>
        <div className="main-list-header__meta-box">
          <div className="main-list-header__meta main-list-header__meta-date" onClick={handleDateSort}>
            <span className={dateColClasses}>
              {t('main-list__date')}
            </span>
          </div>
          <div className="main-list-header__meta main-list-header__meta-size">
            {t('main-list__size')}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MainListHeader = () => {
  const dispatch = useDispatch();

  const isList = useSelector(selectIsList);
  const sortFieldName = useSelector(selectSortFieldName);
  const asc = useSelector(selectSortAsc);

  const handleNameSort = () => {
    const order = sortFieldName === SortFields.name ? !asc : true;
    dispatch(sortUpdate({ fieldName: SortFields.name, asc: order }));
    sendGtmTrigger('sv2_sort_use_in_table');
  };

  const handleDateSort = () => {
    const order = sortFieldName === SortFields.date ? !asc : true;
    dispatch(sortUpdate({ fieldName: SortFields.date, asc: order }));
    sendGtmTrigger('sv2_sort_use_in_table');
  };

  const props = {
    isList,
    sortFieldName,
    asc,
    handleNameSort,
    handleDateSort,
  };

  return <MainListHeaderComponent {...props} />;
};
