import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SettingsLayout } from 'components/layouts/SettingsLayout';
import { MainHeaderClean } from 'components/MainHeader';
import { DevicesList } from 'components/devices/DevicesList';
import { Spinner } from 'components/common/Spinner';

import { fetchingDevicesCatalog, selectDevicesCatalogLoading } from 'redusers/global/devicesCatalog';
import { devicesFetchingStart, selectDevicesLoading } from 'redusers/global/devices';
import { IDevicesPageComponentProps } from './devicesPage.types';

export const DevicesPageComponent = ({ devicesLoading, catalogLoading }: IDevicesPageComponentProps) => {
  const { t } = useTranslation();
  return (
    <SettingsLayout hiddenAvatar={false} withoutThemeSwitcherInMobile>
      <MainHeaderClean title={t('settings__my_devices')} />
      <div className="container">
        {devicesLoading || catalogLoading ? <Spinner /> : <DevicesList />}

      </div>
    </SettingsLayout>
  );
};

export const DevicesPage = () => {
  const dispatch = useDispatch();
  const catalogLoading = useSelector(selectDevicesCatalogLoading);
  const devicesLoading = useSelector(selectDevicesLoading);

  useEffect(() => {
    dispatch(fetchingDevicesCatalog());
    dispatch(devicesFetchingStart());
  }, [dispatch]);

  return <DevicesPageComponent catalogLoading={catalogLoading} devicesLoading={devicesLoading} />;
};
