import React from 'react';

import { IMainHeaderCleanProps } from './types';
import './index.scss';

export const MainHeaderClean = ({ className, title }: IMainHeaderCleanProps) => {
  return (
    <div className="container">
      <div className={`main-header ${className}`}>
        <div className="main-header__left-box">
          <div className="main-header__field-name">{title}</div>
        </div>
      </div>
    </div>
  );
};
