import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';

import { Spinner, SpinnerType } from '../../../common/Spinner';
import SelectWithSearchResultList from './SelectWithSearchResultList';

import './index.scss';

const SelectWithSearch = ({
  field,
  form,
  className,
  getData,
  loadMore,
  afterChangeFieldCb,
  hasMore,
  renderItem,
  onClose,
  data,
  loading,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setSetQuery] = useState('');
  const [isEdit, setIsEdit] = useState('');

  const { name, value, onChange, ...newField } = field;
  const { setFieldValue } = form;

  const setItem = (val) => {
    const currentItem = findItem(val);
    setFieldValue(name, currentItem);
    if (afterChangeFieldCb && val !== value) afterChangeFieldCb(currentItem);
    setSetQuery('');
    setIsEdit(false);
  };

  const handleLoadMore = (page) => {
    if (loadMore) loadMore(query, page);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setSetQuery('');
    setIsEdit(false);
    if (onClose) onClose();
    getData('');
  }, [onClose, getData]);
  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleChange = (e) => {
    setIsEdit(true);
    setSetQuery(e.target.value);
    getData(e.target.value);
  };

  const handleClick = (e) => {
    const currItem = e.nativeEvent.composedPath().find((element) => element?.dataset?.value);
    if (currItem) {
      setItem(currItem?.dataset?.value);
      handleClose();
    }
  };

  const findItem = useCallback((fieldValue) => {
    return data.find((item) => item.value === fieldValue);
  }, [data]);

  const findName = useCallback((fieldValue) => {
    return fieldValue?.name || '';
  }, []);

  const renderList = () => {
    if (!isOpen) return null;

    return (
      <>
        {!!data.length && (
          <div className="select-with-search__list-box">
            <div className="select-with-search__list" onClick={handleClick}>
              {
                loadMore
                  ? (
                    <SelectWithSearchResultList
                      handleLoadMore={handleLoadMore}
                      data={data}
                      hasMore={hasMore}
                      renderItem={renderItem}
                    />
                  )
                  : <SelectWithSearchResultList withoutScroll data={data} renderItem={renderItem} />
              }
            </div>
          </div>
        )}
      </>
    );
  };

  const elementClasses = classNames('select-with-search', { 'select-with-search--open': isOpen });

  useEffect(() => {
    document.addEventListener('click', handleClose);
    return () => document.removeEventListener('click', handleClose);
  });

  return (
    <div className={elementClasses} onClick={(e) => e.stopPropagation()}>
      <input
        className={`form-control ${className}`}
        type="text"
        name={name}
        {...newField}
        {...props}
        onFocus={handleOpen}
        onChange={handleChange}
        value={isEdit ? query : findName(value)}
      />
      { loading && <Spinner size={16} className="select-with-search__spinner" type={SpinnerType.clipLoader} /> }
      {renderList()}
    </div>
  );
};

export default SelectWithSearch;
