import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { StaticPageLayout } from 'components/layouts/StaticPageLayout';
import { SupportFormComponent } from 'components/forms/SupportForm';
import http from 'utils/http.service';

import './index.scss';

export const SupportPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBack = () => {
    history.push('/');
    setComplete(false);
  };

  const handleSubmit = async ({ country, device, ...fields }: any) => {
    try {
      setLoading(true);
      const { status } = await http('/feedback', {
        method: 'post',
        data: { ...fields, country: country.name, device_name: device.name },
        withoutAccess: true,
      });
      if (status === 200) setComplete(true);
    } catch (e) {
      setComplete(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StaticPageLayout
      globalClassName="support-page"
      className="container"
      logoWithoutLink={false}
    >
      <div className="support-page__container">
        {
          complete
            ? (
              <>
                <div className="support-page__header">
                  <h1>{t('support-page__complete-title')}</h1>
                  <p>{t('support-page__complete-desc')}</p>
                </div>
                <div className="support-page__btn-box">
                  <button className="btn sv-btn-blue sv-btn" onClick={handleBack}>
                    {t('support-page__complete-btn')}
                  </button>
                </div>
              </>
            )
            : (
              <>
                <div className="support-page__header">
                  <h1>{t('support-page__title')}</h1>
                  <p>{t('support-page__desc')}</p>
                </div>
                <SupportFormComponent
                  className="support-page__form"
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              </>
            )
        }
      </div>
    </StaticPageLayout>
  );
};
