import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import informationOutline from '@iconify-icons/mdi/information-outline';

import { fileSizeConverter } from 'utils/fileSizeConverter';
import { IDetailsManyComponentProps } from './details.types';

export const DetailsMany = ({ totalSize }: IDetailsManyComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={informationOutline} className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('context-modal__files-details')}
        </div>
      </Modal.Header>
      <Modal.Body>
        <dl className="row">
          <dt className="col-5 col-sm-4">{t('context-modal__details-size')}</dt>
          <dd className="col-7 col-sm-8">
            {fileSizeConverter(totalSize)}
          </dd>
        </dl>
      </Modal.Body>
    </Fragment>
  );
};
