import React from 'react';
import classNames from 'classnames';
import { SkeletonLine } from 'components/common/SkeletonLine';

import { IMainFilesListItemSkeletonProps } from './types';
import './index.scss';

export const MainFilesItemSkeleton = ({ className, isList }: IMainFilesListItemSkeletonProps) => {
  const elementClasses = classNames(className, {
    'main-files-item_list': isList,
    'main-files-item_tile': !isList,
  });

  return (
    <div className={elementClasses}>
      <div
        className="main-files-item__wrap"
      >
        <div className="main-files-item__icon">
          <SkeletonLine className="main-files-item__icon__skeleton" />
        </div>
        <div className="main-files-item__info">
          <div className="main-files-item__info-name skeleton">
            <SkeletonLine />
          </div>
          <div className="main-files-item__meta-box">
            <div className="main-files-item__info-meta main-files-item__info-meta-date">
              <SkeletonLine />
            </div>
            <div className="main-files-item__info-meta main-files-item__info-meta-size">
              <SkeletonLine />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
