import React, { useEffect, useState, useRef, useCallback, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { throttle } from 'lodash';

import './index.scss';

import sendGtmTrigger from '../../../utils/sendGtmTrigger';

import { selectFileClear, selectFileFromFrame } from '../../../redusers/files/selected';
import { FILE_TYPE } from '../../../constants/elementsTypes';

let elements = [];

const MouseBorderSelect = forwardRef((props, ref) => {
  const borderSelectionContainer = document.getElementById('mouse-border-select');
  const defaultPositionState = {
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [positions, setPositions] = useState(defaultPositionState);

  const borderRef = useRef();
  const myPositionRef = React.useRef(positions);
  const isOpenRef = React.useRef(isOpen);

  const checkPosition = (element, frame) => {
    const aLeftOfB = element.right < frame.left;
    const aRightOfB = element.left > frame.right;
    const aAboveB = element.top > frame.bottom;
    const aBelowB = element.bottom < frame.top;

    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
  };

  const handleSelect = useCallback(throttle((currPositions) => {
    elements.forEach((item) => {
      const elementPosition = item.getBoundingClientRect();
      const lastSelected = item?.dataset?.itemSelected;
      const isSelected = checkPosition(
        {
          top: elementPosition.top + window.scrollY,
          bottom: elementPosition.bottom + window.scrollY,
          left: elementPosition.left,
          right: elementPosition.right,
        },
        {
          top: currPositions.y,
          bottom: currPositions.y + currPositions.height,
          left: currPositions.x,
          right: currPositions.x + currPositions.width,
        }
      );

      if (isSelected && lastSelected !== 'true') {
        const itemIdx = parseInt(item?.dataset?.itemIdx);
        if (itemIdx >= 0) item.setAttribute('data-item-selected', 'true');
      }

      if (!isSelected && lastSelected === 'true') {
        const itemIdx = parseInt(item?.dataset?.itemIdx);
        if (itemIdx >= 0) item.setAttribute('data-item-selected', 'false');
      }
    });
  }, 50), []);

  const handleClick = (e) => e.stopPropagation();

  const handleMoueMove = (e) => {
    const { pageX, pageY } = e;
    const newState = {};

    // условие с координатами надо, что бы при обычном клике не навешивалсоь событие handleClick
    if (!isOpenRef.current
      && (
        myPositionRef.current.startX > pageX + 6
        || myPositionRef.current.startX + 6 < pageX
        || myPositionRef.current.startY > pageY + 6
        || myPositionRef.current.starY + 6 < pageY
      )) {
      // одноразовое событие, которые выполнится в момент отжатия кнопки
      // нужно для того что бы предотвратить распростарние клика и выполенния дургих обработчиков
      window.addEventListener('click', handleClick, { capture: true, once: true });
      setIsOpen(true);
    }

    if (pageX >= myPositionRef.current.startX) {
      newState.width = pageX - myPositionRef.current.startX;
    } else if (pageX < myPositionRef.current.startX) {
      newState.width = myPositionRef.current.startX - pageX;
      newState.x = pageX;
    }

    if (pageY >= myPositionRef.current.startY) {
      newState.height = pageY - myPositionRef.current.startY;
    } else if (pageY < myPositionRef.current.startY) {
      newState.height = myPositionRef.current.startY - pageY;
      newState.y = pageY;
    }
    handleSelect({ ...myPositionRef.current, ...newState });
    setPositions((state) => ({ ...state, ...newState }));
  };

  const handleMouseUp = () => {
    const filesIdxs = [];
    elements.forEach((item) => {
      if (item?.dataset?.itemSelected === 'true' && item?.dataset?.itemType === FILE_TYPE) {
        filesIdxs.push(parseInt(item?.dataset?.itemIdx));
      }
      item.setAttribute('data-item-selected', 'false');
    });

    dispatch(selectFileFromFrame(filesIdxs));
    sendGtmTrigger('sv2_select_files_frame');

    elements = [];
    setIsOpen(false);
    setPositions(defaultPositionState);
    ref.current.removeEventListener('mousemove', handleMoueMove);
    window.removeEventListener('mouseup', handleMouseUp);

    borderRef.current.removeEventListener('mousemove', handleMoueMove);
  };

  const handleMouseDown = (e) => {
    //  проверяем, что б была нажата только левая кнопка мыши
    if (e.button !== 0) return null;

    const elementInitiator = e.composedPath().find((element) => element?.dataset?.itemType);

    if (!elementInitiator) {
      dispatch(selectFileClear());
      elements = document.getElementsByName('MOUSE_SELECTED_ELEMENT');
      // setIsOpen(true);
      setPositions((state) => ({ ...state, startX: e.pageX, startY: e.pageY, x: e.pageX, y: e.pageY }));

      ref.current.addEventListener('mousemove', handleMoueMove);
      window.addEventListener('mouseup', handleMouseUp);
      borderRef.current.addEventListener('mousemove', handleMoueMove);
    }
  };

  const handleSelectStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const element = ref.current;
    const elementBorder = borderRef.current;
    element.addEventListener('mousedown', handleMouseDown);

    document.addEventListener('selectstart', handleSelectStart);
    return () => {
      elements = [];
      element.removeEventListener('mousedown', handleMouseDown);
      element.removeEventListener('mousemove', handleMoueMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('click', handleClick, { capture: true });
      document.removeEventListener('selectstart', handleSelectStart);
      elementBorder.removeEventListener('mousemove', handleMoueMove);
    };
  }, []);

  useEffect(() => {
    myPositionRef.current = positions;
    isOpenRef.current = isOpen;
  }, [positions, isOpen]);

  const elementClasses = classNames('mouse-border-select', {
    'mouse-border-select--open': isOpen,
  });

  const renderEl = () => {
    return (
      <div
        className={elementClasses}
        style={{
          top: `${positions.y}px`,
          left: `${positions.x}px`,
          width: `${positions.width}px`,
          height: `${positions.height}px`,
        }}
        ref={borderRef}
      />
    );
  };

  return ReactDOM.createPortal(
    renderEl(),
    borderSelectionContainer
  );
});

export default MouseBorderSelect;
