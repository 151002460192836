import qs from 'query-string';
import { AxiosResponse } from 'axios';
import http from 'utils/http.service';
import { PAGINATION_LIMIT } from 'constants/pagination';
import { SortFields } from 'redusers/sort';
import { FileTypes } from 'types/file.types';
import { IFileRes, IUpdateFileValues } from './files.types';

interface IGetFilesProps {
  sortFieldName: SortFields;
  sortAsc: boolean;
  fileType: FileTypes | null;
  filterDeviseSerials: string[];
  searchStr: string;
  currentPage: number;
}

const getFiles = async ({
  sortFieldName,
  sortAsc,
  fileType,
  filterDeviseSerials,
  searchStr,
  currentPage = 0,
}: IGetFilesProps): Promise<AxiosResponse<IFileRes[]>> => {
  const query: { [key: string]: any } = {
    limit: PAGINATION_LIMIT,
    offset: PAGINATION_LIMIT * currentPage,
    [`sort[${sortFieldName}]`]: sortAsc ? 'asc' : 'desc',
  };

  if (fileType) query.type = fileType;
  if (filterDeviseSerials && filterDeviseSerials.length) {
    query.device_serials = filterDeviseSerials;
  }

  if (searchStr) {
    query.query = searchStr;
  } else {
    query.only_root = true;
  }

  const queryString = qs.stringify(query, { arrayFormat: 'bracket' });

  return http<IFileRes[]>(`/files?${queryString}`);
};

const getPublicFile = async (shareKey: string): Promise<AxiosResponse<IFileRes>> => {
  return http<IFileRes>(`/files/public/${shareKey}`, { withoutAccess: true });
};

export const getSearchHints = async (query: string): Promise<AxiosResponse<IFileRes[]>> => {
  const queryString = qs.stringify({ limit: 5, query });
  return http<IFileRes[]>(`/files?${queryString}`);
};

export const getFileById = async (id: string): Promise<AxiosResponse<IFileRes>> => {
  return http<IFileRes>(`/files/${id}`);
};

export const updateFile = async (id: string, values: IUpdateFileValues): Promise<AxiosResponse<IFileRes>> => {
  return http<IFileRes>(`/files/${id}`, { method: 'patch', data: values });
};

export const deleteFile = async (ids: string[]): Promise<AxiosResponse> => {
  const fileQueryString = qs.stringify({ id: ids }, { arrayFormat: 'bracket' });
  return http(`/files?${fileQueryString}`, { method: 'delete' });
};

export const shareFile = async (id: string, isOpen: boolean): Promise<AxiosResponse<{ share_url?: string }>> => {
  return http<{ share_url?: string }>(
    `/files/${id}/share`,
    { method: isOpen ? 'post' : 'delete' },
  );
};

export default {
  getFiles,
  getPublicFile,
  getSearchHints,
  getFileById,
  updateFile,
  deleteFile,
  shareFile,
};
