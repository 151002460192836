import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '../../../common/Spinner';

const SelectWithSearchResultList = ({ withoutScroll, handleLoadMore, hasMore, data, renderItem }) => {
  const renderList = () => (
    data.map((item) => {
      return (
        <div
          className="select-with-search__item"
          key={item.value}
          data-value={item.value}
        >
          {renderItem ? renderItem(item) : item.name}
        </div>
      );
    })
  );

  if (withoutScroll) return renderList();

  return (
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loadMore={handleLoadMore}
      hasMore={hasMore}
      threshold={400}
      loader={<Spinner size={20} key="spinner_loader" />}
      useWindow={false}
    >
      {renderList()}
    </InfiniteScroll>
  );
};

SelectWithSearchResultList.propTypes = {
  withoutScroll: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  data: PropTypes.array
};

SelectWithSearchResultList.defaultProps = {
  withoutScroll: false,
  handleLoadMore: () => {},
  hasMore: true,
  data: []
};

export default SelectWithSearchResultList;
