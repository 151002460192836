import { combineEpics } from 'redux-observable';

import { fileListEpics } from './files/list';
import { authEpics } from './global/auth';
import { filtersEpics } from './filters';
import { searchEpics } from './search';
import { sharingEpics } from './sharing';
import { sortEpics } from './sort';
import { fileModalEpics } from './fileModal';
import { downloadManagerEpics } from './downloadManager';
import { quotaEpics } from './global/quota';
import { countriesEpics } from './global/countries';
import { devicesEpics } from './global/devices';
import { devicesCatalogEpics } from './global/devicesCatalog';
import { TRootState } from './index';

const epics = combineEpics<any, any, TRootState>(
  ...authEpics,
  ...filtersEpics,
  ...fileListEpics,
  ...searchEpics,
  ...sharingEpics,
  ...sortEpics,
  ...fileModalEpics,
  ...quotaEpics,
  ...countriesEpics,
  ...devicesEpics,
  ...devicesCatalogEpics,
  ...downloadManagerEpics,
);

export default epics;
