import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { SettingsLayout } from 'components/layouts/SettingsLayout';
import { SettingsHeader } from 'components/settings/SettingsHeader';
import { SettingsMenu } from 'components/settings/SettingsMenu';

import { updateQuotaStart } from 'redusers/global/quota';

export const SettingsPageComponent = () => {
  return (
    <SettingsLayout className="settings-page">
      <div className="container">
        <SettingsHeader />
        <SettingsMenu />
      </div>
    </SettingsLayout>
  );
};

export const SettingsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateQuotaStart());
  }, [dispatch]);

  return <SettingsPageComponent />;
};
