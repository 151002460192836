import qs from 'query-string';
import http, { AxiosResponse } from 'utils/http.service';
import { IDeviceRes, IDeviceCatalogRes, IDeviceFirmwareRes, IGetDeviceFirmwareProps } from './devices.types';

export const getDevices = async (): Promise<AxiosResponse<IDeviceRes[]>> => {
  return http<IDeviceRes[]>('/devices?custom=false');
};

export const getDevicesFromCatalog = async (): Promise<AxiosResponse<IDeviceCatalogRes[]>> => {
  // const queryString = qs.stringify({ application_versions: ['SV2', 'SV1'] }, { arrayFormat: 'bracket' });
  return http<IDeviceCatalogRes[]>('/devices/catalog', { withoutAccess: true });
};

export const getLastDeviceFirmwares = async (props: IGetDeviceFirmwareProps[]): Promise<AxiosResponse<IDeviceFirmwareRes[]>> => {
  return http<IDeviceFirmwareRes[]>('/firmwares/latest', { method: 'post', data: props });
};

export default { getDevices, getDevicesFromCatalog, getLastDeviceFirmwares };
