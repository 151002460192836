import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import informationOutline from '@iconify-icons/mdi/information-outline';
import { FileTypes } from 'types/file.types';
import { fileSizeConverter } from 'utils/fileSizeConverter';
import formatDate from 'utils/formatDate';

import { IDetailsProps } from './details.types';

export const DetailsSingle = ({ data }: IDetailsProps) => {
  const { t } = useTranslation();

  const renderType = () => {
    if (data?.metadata?.type === FileTypes.photo) return t('context-modal__details-img');
    if (data?.metadata?.type === FileTypes.video) return t('context-modal__details-video');
    return data?.metadata?.type;
  };

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={informationOutline} className="custom-modal__icon" />
        <div className="custom-modal__title">
          {t('context-modal__file-details')}
        </div>
      </Modal.Header>
      <Modal.Body className="custom-modal__details">
        <dl className="row">
          <dt className="col-5 col-sm-4">{t('context-modal__details-name')}</dt>
          <dd className="col-7 col-sm-8">
            {data.name}
          </dd>

          <dt className="col-5 col-sm-4">{t('context-modal__details-type')}</dt>
          <dd className="col-7 col-sm-8">
            {renderType()}
          </dd>

          <dt className="col-5 col-sm-4">{t('context-modal__details-size')}</dt>
          <dd className="col-7 col-sm-8">
            {fileSizeConverter(data?.metadata?.size)}
          </dd>

          <dt className="col-5 col-sm-4">{t('context-modal__details-date')}</dt>
          <dd className="col-7 col-sm-8">{formatDate(data.metadata.created_at)}</dd>
        </dl>
      </Modal.Body>
    </Fragment>
  );
};