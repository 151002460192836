import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const StaticTextBlock = ({ number, title, paragraphs, withoutNumber, className }) => {
  const renderP = (text, key) => (<p key={key} dangerouslySetInnerHTML={{ __html: text }} />);
  const renderUl = (items, key) => {
    return (
      <ul key={key}>
        {items.map((item, idx) => (<li key={idx} dangerouslySetInnerHTML={{ __html: item }} />))}
      </ul>
    );
  };

  const renderContent = (paragraph, key) => {
    switch (paragraph.type) {
      case 'p':
        return renderP(paragraph.text, key);

      case 'ul':
        return renderUl(paragraph.items, key);

      default:
        return renderP(paragraph.text ? paragraph.text : paragraph, key);
    }
  };

  return (
    <div className={`static-text-block ${className}`}>
      <div className="static-text-block__block">{withoutNumber ? title : `${number}. ${title}`}</div>
      {paragraphs.map((paragraph, idx) => renderContent(paragraph, `paragraph-${idx}`))}
    </div>
  );
};

StaticTextBlock.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  paragraphs: PropTypes.array.isRequired,
  className: PropTypes.string,
};

StaticTextBlock.defaultProps = {
  className: '',
};

export default StaticTextBlock;
