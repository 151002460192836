import React from 'react';
import { SortPicker } from 'components/SortPicker';
import { FilterPicker } from 'components/FilterPicker';

import './mainScreenTopPanel.scss';

export const MainScreenTopPanel = () => {
  return (
    <div className="main-screen__top-panel d-flex d-md-none">
      <FilterPicker />
      <SortPicker />
    </div>
  );
};
