import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFileRes } from 'api/files.types';
import { ISharingState } from './types';

const initialState: ISharingState = {
  loading: true,
  success: false,
  data: undefined,
};

export const sharingSlice = createSlice({
  name: 'sharing',
  initialState,
  reducers: {
    shareFileFetchingStart(state, action: PayloadAction<{ shareKey: string }>) {},
    shareFileFetchingFailed(state) {
      state.loading = false;
      state.success = false;
      state.data = undefined;
    },
    shareFileFetchingSuccess(state, action: PayloadAction<IFileRes>) {
      state.loading = false;
      state.success = true;
      state.data = action.payload;
    },
  },
});

export const { shareFileFetchingStart, shareFileFetchingFailed, shareFileFetchingSuccess } = sharingSlice.actions;
export const sharingReducer = sharingSlice.reducer;
