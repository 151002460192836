import { createSelector } from 'reselect';
import { TRootState } from 'redusers';
import { ISortState, SortFields } from './types';

export const selectSort = (state: TRootState): ISortState => state.sort;

export const selectSortFieldName = createSelector(
  selectSort,
  (sort: ISortState): SortFields => sort.fieldName,
);

export const selectSortAsc = createSelector(
  selectSort,
  (sort: ISortState): boolean => sort.asc,
);
