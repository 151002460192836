import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import dotsVertical from '@iconify-icons/mdi/dots-vertical';
import closeIcon from '@iconify-icons/mdi/close';

import ContextMenu from 'components/common/ContextMenu';
import isMobile from 'utils/isMobile';
import { selectCountSelectedFile, selectWithCtx, selectFileClear } from 'redusers/files/selected';

import { ISelectionBarComponetProps } from './types';
import './selectionBar.scss';

export const SelectionBarComponent = ({ selectedCount, isShow, handleClear }: ISelectionBarComponetProps) => {
  const { t } = useTranslation();
  const barRef = useRef<HTMLDivElement>(null);

  const elementClasses = classNames('selection-bar', {
    'selection-bar--show': isShow,
  });

  return (
    <div className={elementClasses} ref={barRef}>
      <div className="selection-bar__left">
        <Icon
          icon={closeIcon}
          className="selection-bar__close-icon"
          // @ts-ignore
          onClick={handleClear}
        />
        <div className="selection-bar__count">
          {t('selected-bar__select-label')}
          &nbsp;
          <span translate="no">{selectedCount}</span>
        </div>
      </div>
      <div
        className="selection-bar__context"
        data-context-active="true"
        data-context-from-bar="true"
      >
        <Icon icon={dotsVertical} />
      </div>
      {/* @ts-ignore */}
      <ContextMenu height={360} ref={barRef} />
    </div>
  );
};

export const SelectionBar = () => {
  const dispatch = useDispatch();
  const selectedCount = useSelector(selectCountSelectedFile);
  const withCtx = useSelector(selectWithCtx);

  const handleClear = () => dispatch(selectFileClear());

  const props = {
    handleClear,
    selectedCount,
    isShow: !!selectedCount && !withCtx,
  };

  if (!isMobile()) return null;
  return <SelectionBarComponent {...props} />;
};
