import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Icon } from '@iconify/react';
import squareEditOutline from '@iconify-icons/mdi/square-edit-outline';
import contentCopy from '@iconify-icons/mdi/content-copy';
import accountMultipleOutline from '@iconify-icons/mdi/account-multiple-outline';
import downloadOutline from '@iconify-icons/mdi/download-outline';
import informationOutline from '@iconify-icons/mdi/information-outline';
import deleteOutline from '@iconify-icons/mdi/delete-outline';

import ContextMenuHeader from './ContextMenuHeader';
import sendGtmTrigger from '../../../utils/sendGtmTrigger';
import toast from '../../../utils/toast';
import { openCtxModal } from '../../../redusers/ctxModal';
import { ModalTypes } from '../../../types/modal.types';
import { createDownloadProcessStart } from '../../../redusers/downloadManager';

const ContextMenuBlock = ({
  elShare,
  elUrl,
  elIdx,
  elName,
  elCount,
  itemFromSearch,
  dispatch,
  isMany,
  contextMenuId,
  contextMenuBoxId,
  closeFn,
}) => {
  const { t } = useTranslation();
  // const open = (modalType: ModalTypes) => dispatch(openCtxModal({
  const open = (modalType) => {
    dispatch(openCtxModal({ modalType, fromSearch: itemFromSearch, elementIdx: parseInt(elIdx), isMany }));
  };
  const openRename = () => open(ModalTypes.CTX_MODAL_RENAME);
  const openShare = () => open(ModalTypes.CTX_MODAL_SHARE);
  const openViewDetails = () => {
    open(ModalTypes.CTX_MODAL_DETAILS);

    if (isMany) sendGtmTrigger('sv2_group_open_details');
    else sendGtmTrigger('sv2_file_open_details');
  };

  const openRemove = () => open(ModalTypes.CTX_MODAL_REMOVE);
  const copyLink = () => {
    copy(elShare);
    toast(t('toast__link-copied'), { type: 'info' });
    sendGtmTrigger('sv2_file_copy_link');
  };

  const downloadOne = () => sendGtmTrigger('sv2_file_download');
  const bulkDownload = () => {
    dispatch(createDownloadProcessStart());
    sendGtmTrigger('sv2_group_download');
  };

  const renderShareElements = () => {
    if (isMany) return null;

    return (
      <Fragment>
        <div className="context-menu-block__item" onClick={openShare}>
          <Icon icon={accountMultipleOutline} />
          {t('context-menu__share')}
        </div>
        {
          elShare !== 'false'
            ? (
              <div className="context-menu-block__item" onClick={copyLink}>
                <Icon icon={contentCopy} />
                {t('context-menu__get-link')}
              </div>
            )
            : null
        }
      </Fragment>
    );
  };

  const renderRename = () => {
    if (isMany) return null;

    return (
      <div className="context-menu-block__item" onClick={openRename}>
        <Icon icon={squareEditOutline} />
        {t('context-menu__rename')}
      </div>
    );
  };

  const renderDownload = () => {
    if (isMany) {
      return (
        <div className="context-menu-block__item" onClick={bulkDownload}>
          <Icon icon={downloadOutline} />
          {t('context-menu__download')}
        </div>
      );
    }

    return (
      <a href={elUrl} className="context-menu-block__item" download onClick={downloadOne}>
        <Icon icon={downloadOutline} />
        {t('context-menu__download')}
      </a>
    );
  };

  const renderDetails = () => {
    return (
      <div className="context-menu-block__item" onClick={openViewDetails}>
        <Icon icon={informationOutline} />
        {t('context-menu__details')}
      </div>
    );
  };

  const renderRemove = () => {
    return (
      <div className="context-menu-block__item" onClick={openRemove}>
        <Icon icon={deleteOutline} />
        {t('context-menu__remove')}
      </div>
    );
  };

  return (
    <div id={contextMenuId} className="context-menu-block">
      <ContextMenuHeader elName={elName} elCount={elCount} isMany={isMany} closeFn={closeFn} />
      <div id={contextMenuBoxId} className="context-menu__box">
        {renderRename()}
        {renderShareElements()}
        {renderDownload()}
        {renderDetails()}
        {renderRemove()}
      </div>
    </div>
  );
};

ContextMenuBlock.propTypes = {
  closeFn: PropTypes.func.isRequired,
  elShare: PropTypes.string,
  itemFromSearch: PropTypes.bool,
  isMany: PropTypes.bool,
};

ContextMenuBlock.defaultProps = {
  elShare: 'false',
  itemFromSearch: false,
  isMany: false,
};

export default connect()(ContextMenuBlock);
