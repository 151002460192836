import React, { useMemo } from 'react';
import { AuthCredentialsSocial } from 'api/account.types';
import { IConnectedAccountItemProps } from './connectedAccounts.types';
import { ReactComponent as GoogleIcon } from 'static/icons/google-blue.svg';
import { ReactComponent as PhoneIcon } from 'static/icons/phone-blue.svg';
import { ReactComponent as FbIcon } from 'static/icons/fb-blue.svg';
import { ReactComponent as AppleIcon } from 'static/icons/apple-blue.svg';

import './connectedAccoutItem.scss';

export const ConnectedAccountItem = ({ credential }: IConnectedAccountItemProps) => {
  const renderIcon = useMemo(() => {
    switch (credential.social) {
      case AuthCredentialsSocial.phone:
        return <PhoneIcon />;
      case AuthCredentialsSocial.google:
        return <GoogleIcon />;
      case AuthCredentialsSocial.facebook:
        return <FbIcon />;
      case AuthCredentialsSocial.apple:
        return <AppleIcon />;
      default:
        return <PhoneIcon />;
    }
  }, [credential.social]);

  return (
    <div className="connected-account-item">
      <div className="connected-account-item__icon">
        {renderIcon}
      </div>
      <div className="connected-account-item__name">
        <span>{credential.name}</span>
      </div>
    </div>
  );
};
