import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsLayout } from 'components/layouts/SettingsLayout';
import { Placeholder } from 'components/common/Placeholder';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <SettingsLayout>
      <div className="container">
        <Placeholder text={t('placeholder__page-not-found')} />
      </div>
    </SettingsLayout>
  );
};
