import { Epic, ofType } from 'redux-observable';
import { from, of, interval, timer, tap } from 'rxjs';
import {
  exhaustMap,
  mergeMap,
  catchError,
  map,
  takeWhile,
} from 'rxjs/operators';
import i18next from 'i18next';
import toast from 'utils/toast';
import API from 'api';
import { DownloadProcessStatuses } from 'api/downloadManager.types';
import { TRootState } from 'redusers';

import {
  createDownloadProcessStart,
  createDownloadProcessSuccess,
  createDownloadProcessError,
  updateDownloadProcess,
  downloadProcessFinish,
  deleteDownloadProcess,
} from './downloadManager.slice';

export const createDownloadProcessEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { createDownloadProcess },
) => action$.pipe(
  ofType(createDownloadProcessStart),
  map(() => {
    const { indexes: selectedFilesIndexes } = state$.value.files.selected;
    const files = state$.value.files.list.data;

    return selectedFilesIndexes.map((selectedFilesIndex) => files[selectedFilesIndex]?.id);
  }),
  exhaustMap<string[], any>((selectedFilesIndexes) => {
    if (selectedFilesIndexes.length < 1) {
      toast(i18next.t('context-menu__download-empty'), { type: 'info' });
      return of(createDownloadProcessError());
    }

    const { processes } = state$.value.downloadManager;
    if (processes.length >= 3) {
      toast(i18next.t('download-manager__queue_full'), { type: 'info', delay: 4000 });
      return of(createDownloadProcessError());
    }

    return from(createDownloadProcess(selectedFilesIndexes, 'stream-vision-files')).pipe(
      map(({ data, status }) => {
        if (status !== 201) return createDownloadProcessError();

        return createDownloadProcessSuccess(data);
      }),
      catchError(() => of(createDownloadProcessError())),
    );
  }),
);

// todo останавливать отправку запросов когда процесс был удален со стора
// а не только тогда, когда получаем соответствующий статус
export const checkDownloadProcessEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { checkDownloadProcess },
) => action$.pipe(
  ofType(createDownloadProcessSuccess),
  mergeMap(({ payload }) => {
    return interval(3000).pipe(
      exhaustMap(() => {
        return from(checkDownloadProcess(payload.id)).pipe(
          map(({ status, data }) => {
            if (status !== 200) return { ...payload, status: DownloadProcessStatuses.error };
            return data;
          }),
          catchError(() => of({ ...payload, status: DownloadProcessStatuses.error })),
        );
      }),
      takeWhile((data) => (
        data.status !== DownloadProcessStatuses.error && data.status !== DownloadProcessStatuses.success
      ), true),
      map((data) => {
        return data.status !== DownloadProcessStatuses.success
          ? updateDownloadProcess(data)
          : downloadProcessFinish(data);
      }),
    );
  }),
);

export const deleteDownloadProcessEpic: Epic = (action$) => action$.pipe(
  ofType(downloadProcessFinish),
  exhaustMap(({ payload }) => {
    return timer(3000).pipe(
      map(() => deleteDownloadProcess(payload.id)),
    );
  }),
);

export const downloadManagerEpics = [createDownloadProcessEpic, checkDownloadProcessEpic, deleteDownloadProcessEpic];
