import React from 'react';
import classNames from 'classnames';

import { TopHeader } from 'components/TopHeader';
import { ContextModal } from 'components/common/ContextModal';
import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';
import { ISettingsLayoutProps } from './settingsLayout.types';

export const SettingsLayout = ({
  children,
  className,
  hiddenAvatar = true,
  withoutThemeSwitcherInMobile = false,
}: ISettingsLayoutProps) => {
  const layoutClasses = classNames('default-layouts', className);
  const themeSwitcherClasses = classNames({
    'd-block': !withoutThemeSwitcherInMobile,
    'd-none d-lg-block': withoutThemeSwitcherInMobile,
  });

  return (
    <div>
      <TopHeader hiddenSearch hiddenAvatar={hiddenAvatar} themeSwitcherClassNames={themeSwitcherClasses} />
      <div className={layoutClasses}>
        {children}
      </div>
      <ContextModal />
      <DownloadManagerContainer />
    </div>
  );
};
