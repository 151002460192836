import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/cookieNames';
import { firebaseProvider } from './firebaseProvider';

export class RefreshAuthProvider {
  private isPending = false;

  private promise = (http: any) => {
    return new Promise((resolve) => {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN);
      const accessToken = localStorage.getItem(ACCESS_TOKEN);

      if (!refreshToken || !accessToken) {
        this.deleteSessionCookie();
        this.isPending = false;
        return resolve(false);
      }

      return http('/auth/refresh-token', {
        method: 'post',
        data: {
          access_token: accessToken,
          refresh_token: refreshToken,
        },
        withoutAccess: true,
      })
        .then((resp: any) => {
          if (resp.status !== 200) {
            this.deleteSessionCookie();
            this.isPending = false;
            return resolve(false);
          }

          this.setSessionCookie(resp.data.access_token, resp.data.refresh_token);
          this.isPending = false;
          return resolve(true);
        })
        .catch(() => {
          this.deleteSessionCookie();
          this.isPending = false;
          return resolve(false);
        });
    });
  };

  private currPromise: any = null;

  deleteSessionCookie = (): void => {
    firebaseProvider.singOut();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    window.location.href = '/';
  };

  setSessionCookie = (accessToken: string, refreshToken: string): void => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  };

  getSessionCookie = (): { accessToken: string | null, refreshToken: string | null } => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    return { accessToken, refreshToken };
  };

  refresh = async (http: any) => {
    if (this.isPending) {
      return this.currPromise;
    }

    this.currPromise = this.promise(http).then((result) => result);
    this.isPending = true;
    return this.currPromise;
  };
}

export const refreshAuthProvider = new RefreshAuthProvider();
