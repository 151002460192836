const FILE_TYPE = 'FILE';

const ITEM_IMG = 'photo';
const ITEM_VIDEO = 'video';

const TYPES = [
  FILE_TYPE
];

export {
  FILE_TYPE,
  TYPES,
  ITEM_IMG,
  ITEM_VIDEO,
};
