import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  searchClear,
  changeSearchString,
  selectSearchString,
  selectSearchHints,
} from 'redusers/search';
import { fileModalOpenFromSearchStart } from 'redusers/fileModal';
import isMobile from 'utils/isMobile';
import sendGtmTrigger from 'utils/sendGtmTrigger';

import { ResultItem } from './ResultItem';
import { ISearchComponentProps } from './types';

import './search.scss';

export const SearchComponent = ({
  query,
  hints,
  handleClear,
  handleChangeQuery,
  handleOpenViewer,
  handleOpenResultPage,
}: ISearchComponentProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const elementClasses = classNames('top-search', {
    'top-search--focus': isFocus,
  });

  const clearBtnClasses = classNames('top-search__input-clear', {
    'top-search__input-clear--show': query.length >= 1,
  });

  const handleScroll = () => {
    if (inputRef.current) inputRef.current.blur();
  };

  const handleInputBlur = () => {
    window.removeEventListener('scroll', handleScroll);
  };

  const handleFocus = () => {
    setIsFocus(true);
    if (window.scrollY && window.scrollY > 0) {
      setTimeout(() => {
        window.addEventListener('scroll', handleScroll, { once: true });
      }, 1000);
    } else {
      window.addEventListener('scroll', handleScroll, { once: true });
    }
  };

  const handleBlur = () => {
    setIsFocus(false);
    if (isMobile()) handleClear();
  };

  const open = () => {
    setIsFocus(true);
    if (inputRef.current) inputRef.current.focus();
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    handleChangeQuery(value);
  };

  const handlePress = ({ key }: KeyboardEvent) => {
    if (key === 'Enter' && query.trim().length > 0) {
      handleOpenResultPage(query);
      setIsFocus(false);
      if (inputRef.current) inputRef.current.blur();
    }
  };

  return (
    <div className={elementClasses}>
      <div className="top-search__bg" onClick={handleBlur} />
      <div className="top-search__container">
        <div className="top-search__search-box">
          <div className="top-search__input-box">
            <div className="top-search__input-wrap">
              <input
                type="text"
                className="form-control form-control-lg top-search__input"
                placeholder={t('search__placeholder')}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleInputBlur}
                onKeyPress={handlePress}
                value={query}
                ref={inputRef}
              />
              <div className={clearBtnClasses} onClick={handleClear} />
            </div>
            <div className="top-search__mobile-cross" onClick={handleBlur} />
          </div>
          <div className="top-search__result-box">
            {hints.map((item) => (
              <ResultItem
                key={item.id}
                item={item}
                handleOpenViewer={handleOpenViewer}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="top-search__mobile-icon" onClick={open} />
    </div>
  );
};

export const Search = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const query = useSelector(selectSearchString);
  const hints = useSelector(selectSearchHints);

  const handleClear = () => dispatch(searchClear());
  const handleChangeQuery = (value: string) => dispatch(changeSearchString(value));

  const handleOpenViewer = (fileId: string) => {
    dispatch(push({ pathname, search, hash: '#viewer' }));
    dispatch(fileModalOpenFromSearchStart(fileId));
    sendGtmTrigger('sv2_search_open_hint');
  };

  const handleOpenResultPage = (value: string) => {
    dispatch(push(`/result?query=${value.trim()}`));
    sendGtmTrigger('sv2_search_open_result');
  };

  const props = {
    query,
    hints,
    handleClear,
    handleChangeQuery,
    handleOpenViewer,
    handleOpenResultPage,
  };

  return <SearchComponent {...props} />;
};
