import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { selectAuthLoading } from 'redusers/global/auth';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { SocialLogin } from 'components/login/SocialLogin';
import { PhoneLogin } from 'components/login/PhoneLogin';
import { AppButtons } from 'components/common/AppButtons';
import { ThemeSwitcher } from 'components/common/ThemeSwitcher';

import isMobile from 'utils/isMobile';
import sendGtmTrigger from 'utils/sendGtmTrigger';

import { ILoginPageComponentProps } from './types';
import './loginPage.scss';

const randomInteger = (min: number, max: number) => {
  const rand = min + Math.random() * (max - min);
  return Math.floor(rand);
};

export const LoginPageComponent = ({ loading }: ILoginPageComponentProps) => {
  const { t } = useTranslation();
  const [bgNumber] = useState(randomInteger(0, 5));

  const handleHelpClick = () => sendGtmTrigger('sv2_click_help');

  const logoClasses = classNames('login-page__logo', {
    'login-page__logo--loading': loading,
  });

  const pageClasses = classNames('login-page', {
    'login-page--mobile': isMobile(),
  });

  const authBoxClasses = classNames('login-page__box', {
    'login-page__box--hidden': loading,
  });

  return (
    <AuthLayout className={pageClasses} bgNumber={bgNumber}>
      <Helmet>
        <link rel="preload" as="image" href={`/static/images/bg/login-bg-${bgNumber}.png`} />
        <link rel="preload" as="image" href="/static/images/bg/login-bg-0.png" />
        <link rel="preload" as="image" href="/static/images/bg/login-bg-1.png" />
        <link rel="preload" as="image" href="/static/images/bg/login-bg-2.png" />
        <link rel="preload" as="image" href="/static/images/bg/login-bg-3.png" />
        <link rel="preload" as="image" href="/static/images/bg/login-bg-4.png" />
        <link rel="preload" as="image" href="/static/logo-big.svg" />
        <link rel="preload" as="image" href="/static/logo-big-white.svg" />
      </Helmet>
      <ThemeSwitcher className="d-none" />
      <div className="login-page__main-box">
        <div className={logoClasses}>
          <img className="login-page__logo-white" src="/static/logo-big.svg" alt="Stream Vision 2" />
          <img className="login-page__logo-black" src="/static/logo-big-black.svg" alt="Stream Vision 2" />
        </div>
        <div className={authBoxClasses}>
          <h1>{t('auth-page__title')}</h1>
          <SocialLogin />
          <div className="login-page__delimiter">
            <span className="login-page__delimiter-text">{t('auth-page__or')}</span>
          </div>
          <PhoneLogin />
          <AppButtons />
          <div className="login-page__links">
            <Link to="/support" onClick={handleHelpClick}>
              {t('auth-page__footer-support')}
            </Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export const LoginPage = () => {
  const loading = useSelector(selectAuthLoading);

  return <LoginPageComponent loading={loading} />;
};
