import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { selectAuthAccountAvatar } from 'redusers/global/auth';
import { ThemeSwitcher } from 'components/common/ThemeSwitcher';
import { Search } from 'components/Search';
import { TopHeaderLogo } from './topHeaderLogo';
import { ITopHeaderComponentProps, ITopHeaderProps } from './types';

import './topHeader.scss';

export const TopHeaderComponent = ({
  className,
  avatarUrl,
  hiddenSearch = false,
  hiddenAvatar = false,
  logoWithoutLink = false,
  themeSwitcherClassNames,
}: ITopHeaderComponentProps) => {
  const elementClasses = classNames('top-header', className);

  return (
    <div className={elementClasses}>
      <div className="container">
        <TopHeaderLogo logoWithoutLink={logoWithoutLink} />
        { hiddenSearch ? <div /> : <Search /> }
        <div className="top-header__right-box">
          <div className="top-header__profile-box">
            <ThemeSwitcher className={themeSwitcherClassNames} />
            {
              hiddenAvatar
                ? <div />
                : (
                  <Link to="/settings" className="top-header__profile-avatar-box">
                    <div
                      className="top-header__profile-avatar"
                      style={avatarUrl ? { backgroundImage: `url(${avatarUrl})` } : {}}
                    />
                  </Link>
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const TopHeader = (props: ITopHeaderProps) => {
  const avatarUrl = useSelector(selectAuthAccountAvatar);
  const newProps = { ...props, avatarUrl };

  return <TopHeaderComponent {...newProps} />;
};
