import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { devicesFetchingStart, selectLastConnectedDevice } from '../../../redusers/global/devices';
import { selectSkuByName } from '../../../redusers/global/devicesCatalog';
import { selectAuthCountryName, selectAuthCountryId, selectAuthAccountName } from '../../../redusers/global/auth';

import TextareaAutosize from '../inputs/TextareaAutosize';
import SelectCountry from '../inputs/SelectCountry';
import SelectDevice from '../inputs/SelectDevice';
import validators from '../../../validators';
import supportMap from '../../../validators/supportMap';

const AutoUpdateInitialValues = ({ initialData }) => {
  const { setFieldValue, values } = useFormikContext();
  const { device_name: deviceName } = values;
  const sku = useSelector((state) => selectSkuByName(state, deviceName));

  useEffect(() => {
    const data = initialData;

    const keys = Object.keys(data);
    keys.forEach((key) => {
      setFieldValue(key, data[key]);
    });
  }, [setFieldValue, initialData]);

  useEffect(() => setFieldValue('sku', sku), [setFieldValue, sku]);
  return null;
};

export const SupportForm = ({ className, handleSubmit, loading, initialData }) => {
  const { t } = useTranslation();
  const SupportSchema = validators(supportMap);

  const renderDeviceDetails = (formProps) => {
    const { errors, touched, submitCount, values } = formProps;
    if (!values.device) return null;

    return (
      <div className="col-12">
        <div className="row">
          <div className="form-group col-12 col-md-4">
            <label htmlFor="serial_number">{t('support-form__field-serial_number-label')}</label>
            <Field
              type="text"
              name="serial_number"
              id="serial_number"
              className={`form-control ${errors.serial_number && touched.serial_number && submitCount ? 'is-invalid' : null}`}
              placeholder={t('support-form__field-serial_number-placeholder')}
              autoComplete="off"
            />
          </div>

          <div className="form-group col-12 col-md-4">
            <label htmlFor="firmware_version">{t('support-form__field-firmware_version-label')}</label>
            <Field
              type="text"
              name="firmware_version"
              id="firmware_version"
              className={`form-control ${errors.firmware_version && touched.firmware_version && submitCount ? 'is-invalid' : null}`}
              placeholder={t('support-form__field-firmware_version-placeholder')}
              autoComplete="off"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label htmlFor="hardware_version">{t('support-form__field-hardware_version-label')}</label>
            <Field
              type="text"
              name="hardware_version"
              id="hardware_version"
              className={`form-control ${errors.hardware_version && touched.hardware_version && submitCount ? 'is-invalid' : null}`}
              placeholder={t('support-form__field-hardware_version-placeholder')}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        name: '',
        country: {
          name: '',
          value: '',
        },
        email: '',
        device: {
          name: '',
          value: '',
        },
        sku: '',
        serial_number: '',
        firmware_version: '',
        hardware_version: '',
        description: '',
      }}
      validationSchema={SupportSchema}
      onSubmit={handleSubmit}
    >
      {(formProps) => {
        const { errors, touched, submitCount } = formProps;
        return (
          <Form className={`row ${className}`}>
            <AutoUpdateInitialValues initialData={initialData} />
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="name">{t('support-form__field-name-label')}</label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  className={`form-control ${errors.name && touched.name && submitCount ? 'is-invalid' : null}`}
                  placeholder={t('support-form__field-name-placeholder')}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">{t('support-form__field-country-label')}</label>
                <Field
                  component={SelectCountry}
                  name="country"
                  id="country"
                  className={`form-control ${errors.country && touched.country && submitCount ? 'is-invalid' : null}`}
                  placeholder={t('support-form__field-country-placeholder')}
                  autoComplete="off"
                />
              </div>

            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label htmlFor="email">{t('support-form__field-email-label')}</label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  className={`form-control ${errors.email && touched.email && submitCount ? 'is-invalid' : null}`}
                  placeholder={t('support-form__field-email-placeholder')}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="device_name">{t('support-form__field-device-label')}</label>
                <Field
                  component={SelectDevice}
                  name="device"
                  id="device"
                  className={`form-control ${errors.device_name && touched.device_name && submitCount ? 'is-invalid' : null}`}
                  placeholder={t('support-form__field-device-placeholder')}
                  autoComplete="off"
                />
              </div>
            </div>
            {renderDeviceDetails(formProps)}

            <div className="form-group col-12">
              <label htmlFor="description">{t('support-form__field-description-label')}</label>
              <Field
                component={TextareaAutosize}
                name="description"
                placeholder={t('support-form__field-description-placeholder')}
                className={`${errors.description && touched.description && submitCount ? 'is-invalid' : null}`}
              />
            </div>
            <div className="col-12 support-form__btn-box">
              <button className="btn sv-btn-blue sv-btn" type="submit" disabled={loading}>
                {t('default-form__send')}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export const SupportFormComponent = (props) => {
  const [initialData, setInitialDate] = useState({
    device: {
      name: '',
      value: '',
    },
    serial_number: '',
    firmware_version: '',
    hardware_version: '',
    sku: '',
    country: {
      name: '',
      value: ''
    },
    name: '',
  });
  const dispatch = useDispatch();
  const lastConnectedDevice = useSelector(selectLastConnectedDevice);
  const countryName = useSelector(selectAuthCountryName);
  const countryId = useSelector(selectAuthCountryId);
  const userName = useSelector(selectAuthAccountName);

  useEffect(() => {
    dispatch(devicesFetchingStart());
  }, [dispatch]);

  useEffect(() => {
    const newInitialData = {
      ...initialData,
      device: { name: lastConnectedDevice?.name || '', value: lastConnectedDevice?.name || '' },
      serial_number: lastConnectedDevice ? lastConnectedDevice.serial_number : '',
      firmware_version: lastConnectedDevice ? lastConnectedDevice.software_version : '',
      hardware_version: lastConnectedDevice ? lastConnectedDevice.hardware_version : '',
      sku: lastConnectedDevice ? lastConnectedDevice.sku : '',
      country: { name: countryName || '', value: countryId || '' },
      name: userName || ''
    };
    setInitialDate(newInitialData);
  }, [lastConnectedDevice, countryName, userName, countryId]);

  return <SupportForm initialData={initialData} {...props} />;
};

SupportFormComponent.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SupportFormComponent.defaultProps = {
  className: '',
  loading: false,
};
