import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import multipleFileIcon from '@iconify-icons/mdi/file-document-multiple-outline';
import imageIcon from '@iconify-icons/bx/bxs-image-alt';
import videoIcon from '@iconify-icons/bx/bxs-video';

import { DropDownModalItem } from 'components/common/DropDownModal';
import { FileTypes } from 'types/file.types';

import { IFilterTypeProps } from './types';

export const FilterType = ({ currentType, setType }: IFilterTypeProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <DropDownModalItem className="filter-picker__header">
        {t('filter__type-block')}
      </DropDownModalItem>

      <DropDownModalItem
        className={`filter-picker__item ${!currentType ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(null)}
        data-testid="filter-type-clear"
      >
        <div className="filter-picker__item-wrap">
          <Icon icon={multipleFileIcon} className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__all-file-types')}
            </span>
          </div>
        </div>

      </DropDownModalItem>
      <DropDownModalItem
        className={`filter-picker__item ${currentType === FileTypes.photo ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(FileTypes.photo)}
        data-testid="filter-type-img"
      >
        <div className="filter-picker__item-wrap">
          <Icon icon={imageIcon} className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__images-type')}
            </span>
          </div>
        </div>
      </DropDownModalItem>
      <DropDownModalItem
        className={`filter-picker__item ${currentType === FileTypes.video ? 'filter-picker__item--active' : ''}`}
        handleClick={() => setType(FileTypes.video)}
        data-testid="filter-type-video"
      >
        <div className="filter-picker__item-wrap">
          <Icon icon={videoIcon} className="filter-picker__icon" />
          <div className="filter-picker__item-info">
            <span className="filter-picker__item-name">
              {t('filter__videos-type')}
            </span>
          </div>
        </div>
      </DropDownModalItem>
    </Fragment>
  );
};
