import { AuthMethods, AuthSocial } from 'types/auth.types';

export interface IAccountCountryRes {
  id: string;
  name: string;
}

export interface IAccountCityRes {
  id: string;
  name: string;
  region_name: string;
}

export enum AuthCredentialsSocial {
  phone = 'phone',
  google = 'google',
  facebook = 'facebook',
  apple = 'apple',
}
export interface IAuthCredentialsRes {
  method?: 'firebase';
  name?: string;
  social: AuthCredentialsSocial
}

export interface IAccountRes {
  id: string;
  name?: string;
  avatar_url?: string;
  birthday?: string;
  country?: IAccountCountryRes;
  city?: IAccountCityRes;
  auth_credentials: IAuthCredentialsRes[]
}

export interface ILoginRes {
  auth_tokens: {
    access_token: string;
    refresh_token: string;
  };
  account: IAccountRes;
}

export interface IAuthCredential {
  access_token: string,
  method: AuthMethods,
  social?: AuthSocial,
}

export interface IEditAccountData {
  name: string;
  country_id: string;
}
