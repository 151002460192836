import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import image from '@iconify-icons/bx/bxs-image-alt';
import bxsVideo from '@iconify-icons/bx/bxs-video';
import dotsVertical from '@iconify-icons/mdi/dots-vertical';

import { selectFileIsSelected } from 'redusers/files/selected';
import formatDate from 'utils/formatDate';
import isMobile from 'utils/isMobile';
import { fileSizeConverter } from 'utils/fileSizeConverter';
import { FILE_TYPE } from 'constants/elementsTypes';
import { FileTypes } from 'types/file.types';

import './index.scss';
import { IMainFilesItemComponentProps, IMainFilesItemProps } from './types';

export const MainFilesItemComponent = ({
  className,
  isList,
  file,
  idx,
  isSelected,
}: IMainFilesItemComponentProps) => {
  const { t } = useTranslation();
  const elementClasses = classNames(className, {
    'main-files-item_list': isList,
    'main-files-item_tile': !isList,
    'main-files-item--active': isSelected,
  });

  return (
    <div className={elementClasses}>
      <div
        // @ts-ignore
        name="MOUSE_SELECTED_ELEMENT"
        className="main-files-item__wrap"
        data-context-active={isMobile() ? 'false' : 'true'}
        data-item-type={FILE_TYPE}
        data-item-id={file.id}
        data-item-idx={idx}
        data-item-share={file.share_url || false}
        data-item-url={file.data_url || false}
        data-item-name={file.name}
        data-item-selected="false"
      >
        <div className="main-files-item__icon">
          <img src={file.preview_url} alt={file.name} draggable={false} />
        </div>
        <div className="main-files-item__info">
          {
            file.metadata.type === FileTypes.video
              ? <Icon icon={bxsVideo} className="main-files-item__info-icon" />
              : <Icon icon={image} className="main-files-item__info-icon" />
          }
          <div className="main-files-item__info-name">
            <span className="main-files-item__info-name-text">{file.name}</span>
            { file.share_url ? <span className="main-files-item__info-public d-none d-md-block">{t('global__public')}</span> : null }
          </div>
          <div className="main-files-item__meta-box">
            <div className="main-files-item__info-meta main-files-item__info-meta-date">
              {formatDate(file.metadata.created_at)}
            </div>
            <div className="main-files-item__info-meta main-files-item__info-meta-size">
              {fileSizeConverter(file.metadata.size)}
            </div>
            <div className="main-files-item__info-meta d-md-none">
              {file.share_url ? t('global__public') : null }
            </div>
          </div>
        </div>
        {
          isMobile()
            ? (
              <div
                className="main-files-item__mobile-context"
                data-context-active={isMobile() ? 'true' : 'false'}
                data-item-type={FILE_TYPE}
                data-item-id={file.id}
                data-item-idx={idx}
                data-item-share={file.share_url || false}
                data-item-url={file.data_url || false}
                data-item-name={file.name}
              >
                <Icon icon={dotsVertical} />
              </div>
            )
            : null
        }
      </div>
    </div>
  );
};

export const MainFilesItem = ({ idx, ...props }: IMainFilesItemProps) => {
  const isSelected = useSelector(selectFileIsSelected(idx));

  const newProps = { ...props, idx, isSelected };
  return <MainFilesItemComponent {...newProps} />;
};
