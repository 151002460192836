import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Icon } from '@iconify/react';
import closeIcon from '@iconify-icons/mdi/close';

import isMobile from 'utils/isMobile';
import { useMenuDirection } from 'utils/hooks/useMenuDirection';
import { IDropDownModalProps } from './types';

import './dropDownModal.scss';

export const DropDownModal = ({
  children,
  className,
  renderButton,
  renderFooter,
  mobileHeaderTitle,
  btnWrapClassName,
  footerClassName,
  afterCloseFn,
  beforeOpenFn,
  customIsOpen = false,
  customToggle,
  closeAfterClickByItem = false,
}: IDropDownModalProps) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [direction, setDirection] = useMenuDirection(270, btnRef);

  useEffect(() => {
    if (customToggle) setIsOpen(customIsOpen);
  }, [customIsOpen, customToggle]);

  const toggle = () => {
    if (isMobile() && isOpen) {
      const bodyEl = document.body;
      bodyEl.classList.remove('modal-open');
    }

    if (isMobile() && !isOpen) {
      const bodyEl = document.body;
      bodyEl.classList.add('modal-open');
    }

    if (isOpen && afterCloseFn) afterCloseFn();
    if (!isOpen && beforeOpenFn) beforeOpenFn();
    setDirection();

    if (customToggle) customToggle(!customIsOpen);
    else setIsOpen(!isOpen);
  };

  const elementClasses = classNames(`dropdown-modal dropdown-modal--${direction}`, className, {
    'dropdown-modal--show': isOpen,
    'dropdown-modal--mobile': isMobile(),
  });

  const handleRenderFooter = () => {
    if (!renderFooter) return null;

    return (
      <div className={`dropdown-menu__footer ${footerClassName}`} data-testid="DropDownFooterWrap">
        {renderFooter(toggle)}
      </div>
    );
  };

  const handleClickByItem = (e: any) => {
    if (closeAfterClickByItem) {
      const isDropDownItem = e.nativeEvent.composedPath().find((element: any) => element?.className?.includes('dropdown-menu__item'));
      if (isDropDownItem) toggle();
    }
  };

  return (
    <div className={elementClasses} data-testid="dropDownModal">
      <div onClick={toggle} className={btnWrapClassName} ref={btnRef} data-testid="dropDownBtnWrap">
        {renderButton}
      </div>
      <div className="dropdown-menu__bg" onClick={toggle} />
      <div className="dropdown-menu__box">
        <div className="dropdown-menu__header">
          {/* @ts-ignore */}
          <Icon icon={closeIcon} className="dropdown-menu__header-close" onClick={toggle} />
          <div className="dropdown-menu__header-name">
            {mobileHeaderTitle}
          </div>
        </div>
        <div className="dropdown-menu__items" onClick={handleClickByItem}>
          {children}
        </div>
        {handleRenderFooter()}
      </div>
    </div>
  );
};
