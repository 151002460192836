import { createAsyncThunk } from '@reduxjs/toolkit';
import { TRootState } from 'redusers';

import { ICtxModalOpenReturned, ICtxModalOpenPayload } from './types';

export const openCtxModal = createAsyncThunk<ICtxModalOpenReturned, ICtxModalOpenPayload>(
  'ctxModal/openCtxModal',
  async (values, { getState }) => {
    const { files, fileModal } = getState() as TRootState;

    let data;

    if (values.fromSearch) data = fileModal.dataFromSearch;
    else data = values.elementIdx !== undefined ? files.list.data[values.elementIdx] : undefined;

    return {
      modalType: values.modalType,
      elementIdx: values.elementIdx,
      fromSearch: !!values.fromSearch,
      isMany: !!values.isMany,
      fileIndexes: values.isMany ? files.selected.indexes : [],
      data,
    };
  },
);