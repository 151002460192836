import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { IPayloadWithPagination } from 'types/pagination.types';
import { IFileRes, IUpdateFileValues } from 'api/files.types';
import { IFileListState } from './types';

const initialState: IFileListState = {
  loading: false,
  data: [],
  pagination: {
    currentPage: 0,
    pageCount: 0,
    perPage: 0,
    totalCount: 0,
  },
};

export const filesLoadModeStart = createAction('list/filesLoadModeStart');
export const filesLoadModeFailed = createAction('list/filesLoadModeFailed');
export const fileUpdateStart = createAction<{ id: string, values: IUpdateFileValues }>('list/fileUpdateStart');
export const fileDeleteStart = createAction<string | undefined>('list/fileDeleteStart');
export const fileShareStart = createAction<{ data: IFileRes, isOpen: boolean }>('list/fileShareStart');

export const fileListSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    filesFetchingStart: (state) => {
      state.loading = true;
    },

    filesFetchingSuccess: (state, { payload }: PayloadAction<IPayloadWithPagination<IFileRes[]>>) => {
      state.loading = false;
      state.data = payload.data;
      state.pagination = payload.pagination;
    },
    filesLoadModeSuccess: (state, { payload }: PayloadAction<IPayloadWithPagination<IFileRes[]>>) => {
      state.data = state.data.concat(payload.data);
      state.pagination = payload.pagination;
    },

    filesFetchingFailed: (state) => {
      state.loading = initialState.loading;
      state.data = initialState.data;
      state.pagination = initialState.pagination;
    },
    fileUpdateSuccess: (state, { payload }: PayloadAction<IFileRes>) => {
      state.data = state.data.map((file) => (file.id === payload.id ? payload : file));
    },

    fileDeleteSuccess: (state, { payload }: PayloadAction<string[]>) => {
      state.data = state.data.filter((file) => !payload.includes(file.id));
    },
  },

  // extraReducers: (builder) => {
  //   builder
  //     .addCase('@@router/LOCATION_CHANGE', (state) => {
  //       state.beforeShift = [];
  //       state.indexes = [];
  //       state.lastIndex = null;
  //       state.withCtx = false;
  //     })
  // },
});

export const {
  filesFetchingStart,
  filesFetchingSuccess,
  filesLoadModeSuccess,
  filesFetchingFailed,
  fileDeleteSuccess,
  fileUpdateSuccess,
} = fileListSlice.actions;
export const fileListReducer = fileListSlice.reducer;
