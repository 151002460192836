import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { searchClear, searchStrDetect } from 'redusers/search';

import { DefaultLayout } from 'components/layouts/DefaultLayout';
import MainScreen from 'components/MainScreen';
import { MainHeader } from 'components/MainHeader';

import './index.scss';

const SearchPageComponent = () => {
  return (
    <DefaultLayout className="search-page">
      <MainHeader isSearchResult />
      <MainScreen />
    </DefaultLayout>
  );
};

export const SearchPage = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { query } = qs.parse(search) as { query: string };
    dispatch(searchStrDetect(query));

    return () => {
      dispatch(searchClear());
    };
  }, [dispatch, search]);

  return <SearchPageComponent />;
};
