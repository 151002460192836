import {createSelector} from '@reduxjs/toolkit';
import {TRootState} from 'redusers';
import {AuthCredentialsSocial, IAccountRes, IAuthCredentialsRes} from 'api/account.types';
import {IAuthState} from './types';

export const selectAuthState = (state: TRootState): IAuthState => state.global.auth;

export const selectAuthLoading = createSelector(
  selectAuthState,
  (auth): boolean => auth.loading,
);

export const selectAuthIsAuth = createSelector(
  selectAuthState,
  (auth): boolean => auth.isAuth,
);

export const selectAvatarIsLoading = createSelector(
  selectAuthState,
  (auth): boolean => auth.avatarIsLoading,
);

export const selectUpdateIsLoading = createSelector(
  selectAuthState,
  (auth): boolean => auth.updateIsLoading,
);

export const selectAuthAccount = createSelector(
  selectAuthState,
  (auth) : IAccountRes | null => auth.account,
);

export const selectAuthAccountName = createSelector(
  selectAuthAccount,
  (account): string | undefined => {
    if (!account) return undefined;
    return account.name || account.auth_credentials[0]?.name;
  },
);

export const selectAuthAccountAvatar = createSelector(
  selectAuthAccount,
  (account): string | undefined => {
    if (!account) return undefined;
    return account.avatar_url;
  },
);

export const selectAuthCountryName = createSelector(
  selectAuthAccount,
  (account): string | undefined => {
    if (!account) return undefined;
    return account.country?.name;
  },
);

export const selectAuthCountryId = createSelector(
  selectAuthAccount,
  (account): string | undefined => {
    if (!account) return undefined;
    return account.country?.id;
  },
);

export const selectAccountСredentials = createSelector(
  selectAuthAccount,
  (account): IAuthCredentialsRes[] => {
    if (!account) return [];
    return account.auth_credentials;
  },
);

export const selectSortAccountСredentials = createSelector(
  selectAccountСredentials,
  (authCredentials): IAuthCredentialsRes[] => {
    const order: string[] = Object.keys(AuthCredentialsSocial);
    const compare = (a: IAuthCredentialsRes, b: IAuthCredentialsRes): number => {
      if (order.indexOf(a.social) < order.indexOf(b.social)) return -1;
      if (order.indexOf(a.social) > order.indexOf(b.social)) return 1;
      return 0;
    };

    return authCredentials.slice().sort(compare);
  },
);
