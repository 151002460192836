import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DefaultLayout } from 'components/layouts/DefaultLayout';
import MainScreen from 'components/MainScreen';
import { MainHeader } from 'components/MainHeader';

import { filesFetchingStart } from 'redusers/files/list';

import './homePage.scss';

export const HomePageComponent = () => {
  return (
    <DefaultLayout className="home-page">
      <MainHeader />
      <MainScreen />
    </DefaultLayout>
  );
};

export const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filesFetchingStart());
  }, [dispatch]);

  return <HomePageComponent />;
};
