import React from 'react';
import classNames from 'classnames';

import { TopHeader } from 'components/TopHeader';
import { ContextModal } from 'components/common/ContextModal';
import { SelectionBar } from 'components/SelectionBar';
import { DownloadManagerContainer } from 'components/downloadManager/DownloadManagerContainer';
import { IDefaultLayoutProps } from './types';

export const DefaultLayout = ({ children, className }: IDefaultLayoutProps) => {
  const layoutClasses = classNames('default-layouts', className);

  return (
    <div>
      <TopHeader themeSwitcherClassNames="d-none d-lg-block" />
      <div className={layoutClasses}>
        {children}
      </div>
      <ContextModal />
      <DownloadManagerContainer />
      <SelectionBar />
    </div>
  );
};
