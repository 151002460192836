import React from 'react';

import { initStoreAndHistory } from 'redusers';
import { authFetchingStart } from 'redusers/global/auth';
import { initView } from 'redusers/global/view';
import { fetchingDevicesCatalog } from 'redusers/global/devicesCatalog';

import { LIGHT_THEME, VIEW_IS_LIST } from './constants/cookieNames';
import { AppType } from './types/app.types';

export const initApp = (App: AppType) => {
  const storeAndHistory = initStoreAndHistory();

  // глобальные экшены
  // например проверка авторизаци или получение данных необходимых по всему проекту
  storeAndHistory.store.dispatch(authFetchingStart());
  storeAndHistory.store.dispatch(fetchingDevicesCatalog());
  const isList = localStorage.getItem(VIEW_IS_LIST);
  storeAndHistory.store.dispatch(initView(isList === 'true'));

  const isLight = localStorage.getItem(LIGHT_THEME);
  if (isLight === 'true') document.body.classList.add('light');

  return class RmOrangeSoftRedux extends React.Component {
    render() {
      return <App store={storeAndHistory.store} history={storeAndHistory.history} />;
    }
  };
};
