import React from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';

import { IRoutersProps } from 'types/routers.types';

import { LoginPage } from 'pages/LoginPage';
import { HomePage } from 'pages/HomePage';
import { SearchPage } from 'pages/SearchPage';
import { SettingsPage } from 'pages/SettingsPage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { TermsPage } from 'pages/TermsPage';
import { SharingPage } from 'pages/SharingPage';
import { SupportPage } from 'pages/SupportPage';
import { DevicesPage } from 'pages/DevicesPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { TRootState } from './redusers';

interface IRouts {
  path?: string;
  component: any;
}

const Routers = ({ isAuth, history, loading }: IRoutersProps) => {
  const defaultRouts: IRouts[] = [
    { path: '/privacy', component: PrivacyPage },
    { path: '/terms', component: TermsPage },
    { path: '/therms', component: TermsPage },
    { path: '/files/public/:shareKey', component: SharingPage },
  ];

  const authRouts: IRouts[] = [
    { path: '/', component: HomePage },
    // { path: '/folder/:folderId([0-9]{1,9})', component: HomePage },
    { path: '/result', component: SearchPage },
    { path: '/settings', component: SettingsPage },
    { path: '/devices', component: DevicesPage },
  ];

  const openRoutsAfterRefresh: IRouts[] = [
    { path: '/support', component: SupportPage },
  ];

  const guestRoutes: IRouts[] = [{ component: LoginPage }];

  const renderRouters = () => {
    const currOpenRoutsAfterRefresh = !loading ? openRoutsAfterRefresh : [];
    const currRouts = isAuth ? authRouts : guestRoutes;

    const routs = defaultRouts
      .concat(currOpenRoutsAfterRefresh)
      .concat(currRouts)
      .concat([{ component: NotFoundPage }]);

    return routs.map((rout, idx) => (
      <Route exact path={rout.path} component={rout.component} key={rout.path || idx} />
    ));
  };

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {renderRouters()}
      </Switch>
    </ConnectedRouter>
  );
};

const mapStateToProps = ({ global }: TRootState) => ({
  loading: global.auth.loading,
  isAuth: global.auth.isAuth,
});

export default connect(mapStateToProps)(Routers);
