import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FilterPicker } from 'components/FilterPicker';
import { TableViewSwitch } from 'components/TableViewSwitch';

import { IMainHeaderProps } from './types';
import './index.scss';

export const MainHeader = ({ className, isSearchResult = false }: IMainHeaderProps) => {
  const { t } = useTranslation();

  const renderName = () => {
    if (isSearchResult) {
      return (
        <Fragment>
          <div className="main-header__field-name">
            {t('main__search-result')}
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Link to="/" className="main-header__field-name">
          {t('main__cloud-name')}
        </Link>
      </Fragment>
    );
  };

  return (
    <div className="container">
      <div className={`main-header ${className}`}>
        <div className="main-header__left-box">
          {renderName()}
        </div>
        <div className="main-header__right-box">
          <FilterPicker className="d-none d-md-block" />
          <TableViewSwitch />
        </div>
      </div>
    </div>
  );
};
