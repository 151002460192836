import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { v1 as uuid } from 'uuid';
import md5 from 'md5';

import { refreshAuthProvider } from 'providers/refreshAuthProvider';
import { languageProvider } from 'providers/languageProvider';
import { IHttpResponse, IAxiosRequestConfig } from 'types/http.types';

import { ACCESS_TOKEN, CLIENT_ID } from 'constants/cookieNames';

export type { AxiosResponse } from 'axios';

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': languageProvider.getLanguageKey(),
  },
  validateStatus: (status) => {
    return status >= 200 && status < 500; // возможно понадобится более жесткое условие
  },
});

instance.interceptors.response.use(async (res: IHttpResponse<any>) => {
  if (!res.config.headers.Authorization) return res;

  if (res.status === 403 || res.status === 410) {
    await refreshAuthProvider.deleteSessionCookie();
    return res;
  }

  if (res.status === 401) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const result = await refreshAuthProvider.refresh(http);
    if (!result) return res;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return http(res.config.url, res.config);
  }
  return res;
});

const getClientCredentials = (): { clientId: string, clientSignature: string } => {
  let clientId = localStorage.getItem(CLIENT_ID);

  if (!clientId) {
    clientId = uuid();
    localStorage.setItem(CLIENT_ID, clientId);
  }

  return { clientId, clientSignature: md5(clientId + process.env.REACT_APP_CLIENT_SECRET) };
};

async function http<T>(url?: string, options?: IAxiosRequestConfig): Promise<AxiosResponse<T>> {
  const {
    url: urlFromConfig,
    method = 'get',
    data = {},
    headers = {},
    withoutAccess = false,
  } = options || {};

  const access = withoutAccess ? null : localStorage.getItem(ACCESS_TOKEN);
  const { clientId, clientSignature } = getClientCredentials();

  const axiosHeaders = {
    ...headers,
    'Client-ID': clientId,
    'Client-Signature': clientSignature,
  };

  if (access) axiosHeaders.Authorization = `Bearer ${access}`;

  return instance({
    url: url || urlFromConfig,
    method,
    data,
    headers: axiosHeaders,
  });
}

export type THttp<T> = (url?: string, options?: IAxiosRequestConfig) => Promise<AxiosResponse<T>>;

export default http;
