import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import ContextMenuBlock from './ContextMenuBlock';
import { selectFileWithCtx } from '../../../redusers/files/selected';
import {
  selectSelectedFileIndexes,
  selectCountSelectedFile,
} from '../../../redusers/files/selected';
import isMobile from '../../../utils/isMobile';
import usePositions from '../../../utils/hooks/usePositions';
import { FILE_TYPE } from '../../../constants/elementsTypes';
import './index.scss';

/**
 * реф - элемент на который должно навесится событие открытия эвента
 * contextType - data-context-type у эелмента - сообщает на каком элементе вызывать событие
 * handlerContextMenuOpen - обработчик открытия контекстного меню, что-то делает в родиетльском компоненте
 */

const ContextMenuWrapperComponent = React.forwardRef((
  {
    width,
    height,
    onlyByClick,
    selectedFiles,
    elementsCount,
    selectCurrFile,
    //dispatch
  },
  ref
) => {
  const contextMenuId = 'context-menu';
  const contextMenuBoxId = '"context-menu__box"';
  const contextContainer = document.getElementById('context-menu-root');
  const [isOpen, setIsOpen] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);
  const [positionX, positionY, setCoordinates] = usePositions(width, height);
  const [contextData, setContextData] = useState({
    elType: null,
    elId: null,
    elShare: false,
    elName: null,
    elCount: null,
    elIdx: null,
  });

  const close = () => {
    if (isMobile()) {
      const bodyEl = document.body;
      bodyEl.classList.remove("modal-open");
      setMobileShow(false);
      setTimeout(() => setIsOpen(false), 200);
    } else {
      setIsOpen(false);
    }
  };

  const closeEventBubbling = () => close();

  const closeEventCapturing = (e) => {
    const contextElement = e.composedPath().find((element) => element.id === contextMenuId);
    const contextBoxElement = e.composedPath().find((element) => element.id === contextMenuBoxId);
    if (e.type === 'click' && !contextElement) {
      // прекращаем распростанение события при клике мимо меню
      // для мобильных телефонов, чтобы не открывалась папка, а только закрывалось меню
      e.stopPropagation();
    }

    if ((!contextElement && !contextBoxElement) || contextBoxElement) {
      // document.removeEventListener('mousedown', closeEventCapturing, { capture: true });
      document.removeEventListener('click', closeEventCapturing, { capture: true });
      document.removeEventListener('contextmenu', closeEventCapturing, { capture: true });
    }

    if (contextBoxElement) {
      document.addEventListener(e.type, closeEventBubbling, { once: true });
    } else if (!contextElement) {
      close();
    }
  };

  const closeWithUnsubscribe = () => {
    // document.removeEventListener('mousedown', closeEventCapturing, { capture: true });
    document.removeEventListener('click', closeEventCapturing, { capture: true });
    document.removeEventListener('contextmenu', closeEventCapturing, { capture: true });
    close();
  };

  // проверяем выледин ли элемент, при необходимости выделяем
  const checkSelect = (elIdx, elType) => {
    const check = elType === FILE_TYPE
      ? selectedFiles.includes(parseInt(elIdx))
      : false;

    if (!check && elType === FILE_TYPE) selectCurrFile(elIdx);

    return check;
  };

  const handleContextMenu = (e) => {
    const elementInitiator = e.composedPath().find((element) => element?.dataset?.contextActive === 'true');
    const elIdx = elementInitiator?.dataset?.itemIdx;
    const elType = elementInitiator?.dataset?.itemType;

    let isMany;
    if (elementInitiator?.dataset?.contextFromBar === 'true') {
      isMany = true;
    } else {
      const checkSelection = checkSelect(elIdx, elType);
      isMany = checkSelection && selectedFiles.length > 1;
    }

    if (elementInitiator) {
      e.stopPropagation();

      setContextData({
        elType,
        elIdx,
        elId: elementInitiator?.dataset?.itemId,
        elShare: elementInitiator?.dataset?.itemShare,
        elName: elementInitiator?.dataset?.itemName,
        elCount: elementsCount,
        elUrl: elementInitiator?.dataset?.itemUrl,
        itemFromSearch: elementInitiator?.dataset?.itemFromSearch === 'true',
        isMany,
      });

      setCoordinates(e.clientX, e.clientY);
      setIsOpen(true);

      if (isMobile()) {
        const bodyEl = document.body;
        bodyEl.classList.add("modal-open");
        setTimeout(() => setMobileShow(true), 20);
      }

      // document.addEventListener('mousedown', closeEventCapturing, { capture: true });
      document.addEventListener('click', closeEventCapturing, { capture: true });
      document.addEventListener('contextmenu', closeEventCapturing, { capture: true });
    }

    e.preventDefault();
  };

  useEffect(() => {
    const element = ref.current;

    if (onlyByClick || isMobile()) {
      ref.current.addEventListener('click', handleContextMenu);
    } else {
      ref.current.addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (onlyByClick || isMobile()) {
        element.removeEventListener('click', handleContextMenu);
      } else {
        element.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [onlyByClick, ref, selectedFiles]);

  const elementClasses = classNames('context-menu', {
    'context-menu--mobile': isMobile(),
    'context-menu--mobile-show': mobileShow
  });

  if (!isOpen) return null;

  const renderEl = () => {
    return (
      <div
        className={elementClasses}
        style={{
          top: `${positionY}px`,
          left: `${positionX}px`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <ContextMenuBlock
          {...contextData}
          contextMenuId={contextMenuId}
          contextMenuBoxId={contextMenuBoxId}
          closeFn={closeWithUnsubscribe}
        />
      </div>
    );
  };

  return ReactDOM.createPortal(
    renderEl(),
    contextContainer
  );
});

const ContextMenuWrapper = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const selectedFiles = useSelector(selectSelectedFileIndexes);
  const elementsCount = useSelector(selectCountSelectedFile);

  const selectCurrFile = (elIdx) => dispatch(selectFileWithCtx(parseInt(elIdx)));

  const componentProps = {
    ...props,
    ref,
    selectedFiles,
    elementsCount,
    selectCurrFile,
  };

  return <ContextMenuWrapperComponent {...componentProps} />;
});

ContextMenuWrapper.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onlyByClick: PropTypes.bool,
};

ContextMenuWrapper.defaultProps = {
  width: 230,
  height: 360,
  onlyByClick: false,
};

export default ContextMenuWrapper;
