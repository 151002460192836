import React from 'react';
import { StaticPageLayout } from 'components/layouts/StaticPageLayout';
import { MainHeaderClean } from 'components/MainHeader';
import StaticText from 'components/StaticText';

export const PrivacyPage = () => {
  const title = 'Yukon Advanced Optics Worldwide, UAB Privacy Policy Revised edition of 18 December 2020';
  const content = [
    {
      title: 'General provisions',
      paragraphs: [
        {
          type: 'p',
          text: 'This document (hereinafter referred to as the Privacy Policy) sets out the main principles for collecting, '
            + 'processing, and storing your (Customer\'s) personal data by Yukon Advanced Optics Worldwide, UAB (data is '
            + 'kept with the Register of Legal Entities of the Republic of Lithuania, organization identification number: '
            + '300149641, registered office address: Ateities 21c, Vilnius, Lithuania) (hereinafter referred to as the “Company”).',
        },
        {
          type: 'p',
          text: '<b>The Privacy Policy is designed to protect and defend the Customer\'s personal data from unauthorized use.</b>',
        },
        {
          type: 'p',
          text: 'When processing personal data, we comply with the Regulation, international legal acts, laws of the Republic '
            + 'of Lithuania, and other directly applicable legal acts governing the protection of personal data, also the '
            + 'instructions and recommendations of the competent authorities.',
        },
      ],
    },
    {
      title: 'Terms of Privacy Policy',
      // className: 'static-text-block--without-margin',
      paragraphs: [
        '<b>Personal data</b> means any information relating to a natural person – a data subject, '
        + 'who is identified or can be identified directly or indirectly by reference to respective identifier;',

        '<b>Customer</b> means any person, who orders, purchases, or uses our goods, contacts us, whom we contact, or '
        + 'who has expressed an intention to use or in any way uses the Company\'s services, intends to purchase '
        + 'or purchases the Company\'s goods, and/or is otherwise related to/is interested in the services/goods '
        + 'provided/supplied by the Company;',

        '<b>Processing</b> means any operation, which is performed on personal data (including collection, recording, storage, '
        + 'alteration, granting of access, submission and/or transmission of requests, etc.);',

        '<b>Sign-in information</b> means the Internet Protocol (IP) address and the Internet service provider used to '
        + 'connect the device to the Internet, browser type and version, time zone settings, browser plug-in type '
        + 'and version, operating system and platform, login location, character encoding, Uniform Resource Locator '
        + '(URL), products viewed by the Customer, and other information hosted on the website;',

        '<b>Regulation</b> means the Regulation (EU) 2016/679 of the European Parliament and of the Council of '
        + '27 April 2016 on the protection of natural persons with regard to the processing of personal data and '
        + 'on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).',
      ],
    },
    {
      title: 'The principles governing the processing of personal data',
      paragraphs: [
        'In its activities the Company goes by the following basic principles of principles governing the processing of personal data:',

        {
          type: 'ul',
          items: [
            'Personal data is collected for defined and legitimate purposes.',
            'Personal data is processed in an accurate and fair manner.',
            'Personal data is processed in a lawful manner, i.e. only in cases where:'
            + '<br />a) a data subject gives consent, for example, to receive promotional information;'
            + '<br />b) a contract is concluded or performed, where one of the parties is a data subject;'
            + '<br />c) the Company is obliged to process personal data in accordance with the law;'
            + '<br />d) personal data must be processed for the legitimate interests of the Company or a third party, '
            + 'to which the personal data is provided, provided that the interests of the data subject are not overriding.',
            'Personal data is updated on a regular basis.',
            'Personal data is stored for no longer than required by the purposes, for which it is processed.',
            'Personal data is only processed by employees, who have been granted such a right.',
            'All information about the personal data being processed is treated confidential.',
          ],
        },
      ],
    },

    {
      title: 'Nature of information collected',
      paragraphs: [
        'The Company may collect the following Customer’s data:'
        + '<br />basic information of the Customer: first name, last name, address, copy and details of identity document '
        + '(if made available), other information is necessary to insure proper work - telephone number, and e-mail '
        + 'address, password, nickname',

        '<b>Device information:</b>',
        {
          type: 'ul',
          items: [
            'Device attributes (information such as device ID, operating system, hardware and software versions, '
            + 'battery level, signal strength, available memory) Third party application identifiers (google token, '
            + 'fb token, push token, apple id)',
            'Device Signals (Bluetooth signals and information about nearby Wi-Fi hotspots, beacons, and cell towers)',
            'Data from device settings (information that you allow us to receive by enabling device settings, '
            + 'such as accessing your location (GPS), camera or photo data)',
            'Network and connections (information such as the name of your mobile operator or provider, language, '
            + 'time zone, mobile phone number, IP address, connection speed and, in some cases, information about '
            + 'other devices located nearby or included in your network)',
          ],
        },

        '<b>Information from the connected device:</b>',
        {
          type: 'ul',
          items: [
            'photos from device, videos from device; Device statistics, device information (SKU number of device, '
            + 'hardware Version, hirmware version, serial number of device)',
            'information about the goods purchased by the Customer: information about the selected/provided services '
            + 'and duration thereof, information related to the assessment of the quality of goods and/or services, '
            + 'information about the return of goods, after-sales service, etc.;',
            'information on the Client\'s consent to participate in tenders, participation in opinion polls;',
            'communication: data on browsing the Company’s web pages and self-service web sites (collected using '
            + 'cookies and similar technologies), Customer’s requests and Company’s answers with respect to the points at issue;',
            ' other data: information on prize winners, information about survey participants, and newsletter subscribers.',
          ],
        },
      ],
    },

    {
      title: 'Purposes of data processing',
      paragraphs: [
        'The Company uses the collected data in order to provide services / supply goods, also to provide assistance, '
        + 'send messages, special offers, and information about sales promotion in accordance with the applicable legislation.',

        'For the information processing purposes:',

        {
          type: 'ul',
          items: [
            'concluding and implementing the contract, ensuring quality thereof, also providing customer service and information;',
            'direct marketing and general information on new products (unless you have explicitly opted out);',
            'accounting;',

            'administering and improving the Company\'s web site/ and mobile application, keeping it secure, '
            + 'and ensuring that content in your device is delivered in the most efficient way;',

            'fulfilling legal obligations provided for in legal acts;',

            'such other purposes, for which the Company has the right to process the Customer’s personal data, when '
            + 'the Customer expresses his/her consent, when the data needs to be processed due to the Company\'s '
            + 'legitimate interest, or when the Company is obliged to process the data by relevant legal acts. '
            + 'We may also process personal data for other purposes, if we have obtained the consent of the data '
            + 'subject or we have the right to process the data on the basis of a legitimate interest.',
          ],
        },
      ],
    },
    {
      title: 'Data collection methods',
      paragraphs: [
        'We collect information about you in the following ways:',
        {
          type: 'ul',
          items: [
            'When you provide information to the Company yourself (e.g. by completing the Company\'s applications, '
            + 'contracts, and/or other documents (including through our partners), electronically on our '
            + 'web site/through applications, making inquiries by mail, e-mail, telephone, during live video '
            + 'chat, as well as other means of communication);',

            'Automatically, when you use the Company\'s web site or products – in this way the Customer\'s '
            + 'login data is recorded. Most of the information collected automatically is collected through '
            + 'cookies and other similar technologies (see Rules for the Use of Cookies);',
            'When we receive information about the Customer from third parties, with which we cooperate '
            + '(e.g. partners, public authorities, etc.).',
          ],
        },
      ],
    },
    {
      title: 'Duration of the processing',
      paragraphs: [
        'The Company processes the Customer\'s data only for the time necessary to achieve and implement '
        + 'the purposes specified in this Privacy Policy taking into account the nature of the goods/services '
        + 'provided to the Customers as well as contracts concluded with them, unless a longer period of retention '
        + 'of personal data and related documents is established or permitted under the applicable regulations, '
        + 'which is necessary (e.g. statutory retention periods, statute of limitation of legal proceedings, etc.).',
      ],
    },
    {
      title: 'Data transmission',
      paragraphs: [
        'To achieve the purposes set out in the Privacy Policy, the Company may transfer the Customer\'s data to the following data recipients:',
        {
          type: 'ul',
          items: [
            'Data processors, who provide services and process Customer’s data on behalf of, in the interests of, '
            + 'and by order of the Company (e.g. IT service providers, auditors, consultants, etc.). '
            + 'Data processors only process the Customers personal data in accordance with the expressly given '
            + 'instructions undertaking to ensure the appropriate protection and confidentiality of personal data '
            + 'received for processing from the Company as well as the organizational and technical measures that '
            + 'meet the security requirements, as more specifically discussed in the agreements concluded between '
            + 'the Company and subcontractors on data processing.',

            'The entities entitled to receive information in accordance with the requirements of legal acts '
            + '(e.g. courts, public and municipal authorities, etc.) only to the extent necessary for the '
            + 'proper performance of applicable legal acts.',

            'Other third parties on another lawful basis or with the Customer’s consent, which may be obtained '
            + 'with respect to a specific case.',
          ],
        },
        'Data processors, to which the Company transfers the Customer’s personal data, may be established outside '
        + 'the Republic of Lithuania, the European Union, or the European Economic Area. Data is transferred to such '
        + 'processors, only if it is permitted by the legislative requirements and only by taking all necessary '
        + 'measures to ensure the protection of the Customer’s privacy.',
      ],
    },
    {
      title: 'What are Customer’s rights?',
      paragraphs: [
        'The Customer has the following rights:',
        {
          type: 'ul',
          items: [
            'To contact us and discuss any issues regarding the processing of personal data by the Company.',

            'To obtain confirmation from the Company as to whether or not personal data concerning him/her '
            + 'is being processed, and if such personal data is being processed, he/she has the right to get '
            + 'acquainted with the personal data being processed as well as related information.',

            'To have access to personal data provided by him/her, which is processed on the basis of his/her '
            + 'consent or performance of the contract, in writing or in a commonly used electronic form, and, '
            + 'if possible, request the transfer of such data to another service provider.',

            'To have personal data rectified, if the data is inaccurate, or to have incomplete data supplemented',

            'To ask for your, as of the Customer of the Company, personal data, which is processed with the '
            + 'Customer’s consent, to be deleted. This right does not apply, if the Customer\'s personal data, '
            + 'which is requested to be deleted, is also processed on another legal basis, such as processing '
            + 'necessary for the performance of the contract or performance of obligations under the applicable '
            + 'legislation.',

            'To ask to restrict the processing of your personal data, for example, for a period, during which '
            + 'the Company will analyse whether the Customer has the right to request the same and whether it '
            + 'is practicable to have his/her personal data deleted.',
          ],
        },

        'The user can delete his account with all his data at any time. For example:',
        'When you delete your account at your request, we delete everything, for example your photos and videos, '
        + 'and you will not be able to restore this information later. You can delete your account at any time '
        + 'in your profile settings.',

        {
          type: 'ul',
          items: [
            ' To refuse that the Customer’s personal data is processed on grounds of legitimate interests of the Company '
            + 'or third parties, if the processing of personal data is based on legitimate interests, also if the '
            + 'personal data is processed for direct marketing purposes, including profiling performed for this purpose.',

            'To refuse to be subject to a fully automated decisions, including profiling, when the decisions incur '
            + 'legal effects or similarly significant consequences on the Customer. This right does not apply, if '
            + 'such decisions are necessary for the purposes of concluding or implementing the contract with the '
            + 'Customer, is permitted by applicable legislation, or the Customer has given explicit consent '
            + 'to the processing.',

            'To withdraw your consent for the processing of personal data.',
          ],
        },

        'The Customer shall exercise his/her rights by submitting a specific request using the contact information '
        + 'specified in this Privacy Policy. The Customers shall be provided with the information related to the '
        + 'exercise of their rights free of charge. The Company shall, not later than within 1 month from the '
        + 'receipt of the request, provide the Customer with the information on the actions taken upon receipt of '
        + 'the Customer\'s request regarding the exercise of his/her rights or indicate the reasons for inaction.',

        'The period for submitting the requested information may, as appropriate, be extended for another 2 months '
        + 'depending on the complexity and number of the requests. When the Customer submits the request by electronic '
        + 'means, the information shall also be provided to him/her by electronic means.',

        'Examination of the request received from the Customer regarding the exercise of rights may be refused or an '
        + 'appropriate fee may be charged for the same, if the request is manifestly unfounded or excessive, also in '
        + 'other cases specified in regulatory enactments.',

        'If the Customer believes that his/her personal data is being processed in violation of his/her rights and '
        + 'legitimate interests, the Customer shall have the right to, in accordance with the applicable legal acts, '
        + 'lodge a complaint regarding the processing of personal data to the State Data Protection Inspectorate.'
      ],
    },
    {
      title: 'Privacy Policy updates',
      paragraphs: [
        'As may be required from time to time, the Privacy Policy may be updated, and in case of significant '
        + 'changes to the Privacy Policy, we will try to inform our Customers of the same directly.',
      ],
    },
    {
      title: 'Contact details',
      className: 'static-text-block--without-margin',
      paragraphs: [
        'Please do not hesitate to contact us if you have any questions, requests, or comments regarding the'
        + ' Company\'s Privacy Policy or data processing by:',

        'E-mail: Gdpr@yukonww.com',
        'Contact persons: Lina, Lawyer/GDPR Data Protection Officer',
        'Phone: +370 650 80 625',
        'Address: Ateities 21C, Vilnius, Republic of Lithuania',
      ],
    },
    {
      title: 'Further information',
      paragraphs: [
        'The Company\'s web site and products may contain links to third party web sites. These third party web sites '
        + 'apply their own privacy policies. If you visit these web sites, you will be subject to their respective '
        + 'policies, for which we are not to be held responsible.'
      ],
    },
  ];

  return (
    <StaticPageLayout>
      <MainHeaderClean title="Privacy Policy" />
      <div className="container">
        { /* @ts-ignore */ }
        <StaticText title={title} content={content} />
      </div>
    </StaticPageLayout>
  );
};
