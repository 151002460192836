import React from 'react';
import { StaticPageLayout } from 'components/layouts/StaticPageLayout';
import { MainHeaderClean } from 'components/MainHeader';
import StaticText from 'components/StaticText';

export const TermsPage = () => {
  const title = 'Yukon Advanced Optics Worldwide, UAB Privacy Policy Revised edition of 18 December 2020';
  const content = [
    {
      title: 'Overview',
      paragraphs: [
        'This application is operated by Yukon Advanced Optics Worldwide, UAB by offering this app, including the '
        + 'information, tools, and services provided in this app to you, the user, provided that you accept all '
        + 'links, terms and conditions, rules, as well as notices contained herein. By visiting our website/app, '
        + 'you enter into an agreement with our Service and agree to be bound by these terms and conditions '
        + '(End User License Agreement as well as Terms and Conditions), including any additional provisions, '
        + 'terms and conditions, as well as rules set forth in this document and/or provided on the website/app.',

        'This End User License Agreement applies to all users of the app/website. Please read this End User License '
        + 'Agreement carefully before accessing or using our website/app. If you do not agree to all terms and '
        + 'conditions of this agreement, you may not be granted access to the website or you will not be able '
        + 'to use the services provided. This End User License Agreement constitutes an offer, and upon confirmation '
        + 'thereof, you will be bound by the End User License Agreement. Any new features or tools, which are added '
        + 'to the current website/app, shall also be subject to the terms of this End User License Agreement. '
        + 'You may review the latest version of the End User License Agreement at any time. We reserve the right '
        + 'to update, change, or modify any part of this End User License Agreement by publishing updates and/or '
        + 'changes on our website/app. You are responsible for reviewing this website/app for changes from time to '
        + 'time. Your further use of or access to this website/app after publishing of any changes constitutes your '
        + 'acceptance of those changes.',

        'This End User License Agreement applies to all users of the app/website. Please read this End User License '
        + 'Agreement carefully before accessing or using our website/app. If you do not agree to all terms and '
        + 'conditions of this agreement, you may not be granted access to the website or you will not be able to '
        + 'use the services provided. This End User License Agreement constitutes an offer, and upon confirmation '
        + 'thereof, you will be bound by the End User License Agreement. Any new features or tools, which are added '
        + 'to the current website/app, shall also be subject to the terms of this End User License Agreement. '
        + 'You may review the latest version of the End User License Agreement at any time. We reserve the right '
        + 'to update, change, or modify any part of this End User License Agreement by publishing updates and/or '
        + 'changes on our website/app. You are responsible for reviewing this website/app for changes from time to '
        + 'time. Your further use of or access to this website/app after publishing of any changes constitutes '
        + 'your acceptance of those changes.',
      ],
    },
    {
      title: 'General conditions',
      // className: 'static-text-block--without-margin',
      paragraphs: [
        'We reserve the right to refuse service to anyone for any reason at any time. You agree not to reproduce, '
        + 'duplicate, copy, sell, resell, or exploit any portion of the Service/app, use of the Service/app, '
        + 'or access to the Service/app or any contact on the website, through which the service is provided, '
        + 'without express written permission by us.',
      ],
    },
    {
      title: 'Scope of license',
      paragraphs: [
        'The licensor grants to you a nontransferable license to use the Licensed Application. The terms of '
        + 'this Standard EULA will govern any content, materials, or services accessible from or purchased '
        + 'within the Licensed Application as well as upgrades provided by Licensor that replace or supplement '
        + 'the original Licensed Application. You may not distribute or make the Licensed Application available '
        + 'over a network, where it could be used by multiple devices at the same time. You may not transfer, '
        + 'redistribute, or sublicense the Licensed Application and, if you sell your device to a third party, '
        + 'you must remove the Licensed Application from the device before doing so. You may not copy (except '
        + 'as permitted by this license), reverse-engineer, disassemble, attempt to derive the source code of, '
        + 'modify, or create derivative works of the Licensed Application, any updates, or any part thereof '
        + '(except as and only to the extent that any foregoing restriction is prohibited by applicable law '
        + 'or to the extent as may be permitted by the licensing terms governing use of any open-sourced '
        + 'components included with the Licensed Application).',
      ],
    },
    {
      title: 'Third-party links',
      paragraphs: [
        'Certain content, products, and services available via our Service/app may include materials from '
        + 'third-parties. Third-party links on this site may direct you to third-party websites that are '
        + 'not affiliated with us. We are not responsible for examining or evaluating the content or accuracy '
        + 'and we do not warrant and will not have any liability or responsibility for any third-party materials '
        + 'or websites, or for any other materials, products, or services of third-parties. We are not liable '
        + 'for any harm or damages related to the purchase or use of goods, services, resources, content, '
        + 'or any other transactions made in connection with any third-party websites. Please review carefully '
        + 'the third-party\'s policies and practices and make sure you understand them before you engage in any '
        + 'transaction. Complaints, claims, concerns, or questions regarding third-party products should '
        + 'be directed to the third-party.',
      ],
    },

    {
      title: 'User comments, feedback, and other submissions',
      paragraphs: [
        'If, at our request, you send certain specific submissions (for example contest entries) or without a '
        + 'request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether '
        + 'online, by email, by postal mail, or otherwise (collectively, \'comments\'), you agree that we may, '
        + 'at any time, without restriction, edit, copy, publish, distribute, translate, and otherwise use in '
        + 'any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain '
        + 'any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. '
        + 'We may, but have no obligation to, monitor, edit, or remove content that we determine in our sole '
        + 'discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, '
        + 'or otherwise objectionable or violating any party’s intellectual property or this End User License '
        + 'Agreement. You agree that your comments will not violate any right of any third-party, including copyright, '
        + 'trademark, privacy, personality, or other personal or proprietary right. You further agree that your '
        + 'comments will not contain libelous or otherwise unlawful, abusive, or obscene material, or contain any '
        + 'computer virus or other malware that could in any way affect the operation of the Service/app or any '
        + 'related website. You may not use a false e-mail address, pretend to be someone other than yourself, '
        + 'or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible '
        + 'for any comments you make and their accuracy. We take no responsibility and assume no liability '
        + 'for any comments posted by you or any third-party.',
      ],
    },

    {
      title: 'Personal information',
      paragraphs: [
        'To make it even more convenient for you to use our Service, we may need certain personal information, '
        + 'including, but not limited to, your name, phone number, and postal address. The data we collect will '
        + 'be used exclusively in relation to you or to identify you. For that purpose we have our Privacy Policy '
        + 'in place. Please, view our Privacy Policy.',

        'You agree that we may collect and use technical data and related information, including, but not limited '
        + 'to, technical information about your device, system, and application software, and peripherals that '
        + 'is gathered periodically to facilitate the provision of software updates, product support, and other '
        + 'services to you (if any) related to the Licensed Application. We may use this information, as long as '
        + 'it is in a form that does not personally identify you, to improve our products or to provide services '
        + 'or technologies to you.',
      ],
    },

    {
      title: 'Prohibited uses',
      paragraphs: [
        'In addition to other prohibitions as set forth in the End User License Agreement, you are prohibited from '
        + 'using the site/app or its content: (a) for any unlawful purpose; (b) to solicit others to perform or '
        + 'participate in any unlawful acts; (c) to violate any international, federal, provincial, or state '
        + 'regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property '
        + 'rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, '
        + 'disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, '
        + 'age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or '
        + 'transmit viruses or any other type of malicious code that will or may be used in any way that will affect '
        + 'the functionality or operation of the Service or of any related website, other websites, or the Internet; '
        + '(h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, '
        + 'crawl, or scrape; (j) for any obscene or immoral purpose; or (k) for other unlawful purposes. '
        + 'We reserve the right to terminate your use of the Service or any related website/app for violating '
        + 'any of the prohibited uses.',
      ],
    },

    {
      title: 'Disclaimer of warranties; limitation of liability',
      paragraphs: [
        'We do not guarantee, represent, or warrant that your use of our app/Service/website will be uninterrupted, '
        + 'timely, secure, or error-free. You agree that from time to time we may remove the service for indefinite '
        + 'periods of time or cancel the service at any time, without notice to you. You expressly agree that your '
        + 'use of, or inability to use, the service is at your sole risk. In no case shall Yukon Advanced Optics '
        + 'Worldwide, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, '
        + 'service providers, or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, '
        + 'punitive, special, or consequential damages of any kind, including, but not limited to, lost profits, lost '
        + 'revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, '
        + 'tort (including negligence), strict liability, or otherwise, arising from your use of any of the service '
        + 'or any products procured using the Service, or for any other claim related in any way to your use of the '
        + 'Service/app or any product, including, but not limited to, any errors or omissions in any content, or any '
        + 'loss or damage of any kind incurred as a result of the use of the Service/app or any content (or product) '
        + 'posted, transmitted, or otherwise made available via our Service, even if advised of their possibility. '
        + 'Because some states or jurisdictions do not allow the exclusion or the limitation of liability for '
        + 'consequential or incidental damages, in such states or jurisdictions, our liability shall be limited '
        + 'to the maximum extent permitted by law.',

        'NO WARRANTY. YOU EXPRESSLY AGREE THAT YOUR USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. '
        + 'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED '
        + 'OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED \'AS IS\' AND \'AS AVAILABLE\' FOR YOUR USE WITH '
        + 'ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.  WE HEREBY DISCLAIM ALL WARRANTIES OR CONDITIONS WITH '
        + 'RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES, EITHER EXPRESS OR IMPLIED, INCLUDING ALL IMPLIED '
        + 'WARRANTIES OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A PARTICULAR PURPOSE, '
        + 'DURABILITY, TITLE, AND NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR '
        + 'OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES '
        + 'PROVE DEFECTIVE, YOU SHALL BE ENTITLED TO COMPENSATION FOR ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. '
        + 'SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY '
        + 'RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.',
      ],
    },

    {
      title: 'Indemnification',
      paragraphs: [
        'You agree to indemnify, defend, and hold harmless Yukon Advanced Optics Worldwide and our parent '
        + 'undertakings, subsidiaries, affiliates, partners, officers, directors, agents, contractors, '
        + 'licensors, service providers, subcontractors, suppliers, interns, and employees, from any claim '
        + 'or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out '
        + 'of your breach of this End User License Agreement or the documents they incorporate by reference, '
        + 'or your violation of any law or the rights of a third-party.',
      ],
    },

    {
      title: 'Severability',
      paragraphs: [
        'In the event that any provision of this End User License Agreement is determined to be unlawful, void, '
        + 'or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by '
        + 'applicable law, and the unenforceable portion shall be deemed to be severed from this End User License '
        + 'Agreement. Such determination shall not affect the validity and enforceability of any other remaining provisions.',
      ],
    },

    {
      title: 'Termination',
      paragraphs: [
        'The obligations and liabilities of the parties incurred prior to the termination date shall survive '
        + 'the termination of this agreement for all purposes. This End User License Agreement is effective '
        + 'unless and until terminated by either you or us. You may terminate this End User License Agreement '
        + 'at any time by notifying us that you no longer wish to use our Service, or when you cease using our '
        + 'Service. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term '
        + 'or provision of this End User License Agreement, we also may terminate this agreement at any time without '
        + 'notice and you will remain liable for all amounts due up to and including the date of termination; and/or '
        + 'accordingly may deny you access to our Service (or any part thereof).',
      ],
    },

    {
      title: 'Entire agreement',
      paragraphs: [
        'The failure of us to exercise or enforce any right or provision of this End User License Agreement shall '
        + 'not constitute a waiver of such right or provision. This End User License Agreement and any policies or '
        + 'operating rules posted by us on this app/site or in respect to the Service constitutes the entire '
        + 'agreement and understanding between you and us and govern your use of the Service superseding '
        + 'any prior or contemporaneous agreements, communications, and proposals, whether oral or written, '
        + 'between you and us (including, but not limited to, any prior versions of this End User License Agreement). '
        + 'Any ambiguities in the interpretation of this End User License Agreement shall not be construed against the drafting party.',
      ],
    },
    {
      title: 'Governing law',
      paragraphs: [
        'This Agreement and the relationship between you and Yukon Advanced Optics Worldwide shall be governed by '
        + 'the laws of the Republic of Lithuania, excluding its conflicts of law provisions. You and Yukon Advanced '
        + 'Optics Worldwide agree to submit to the personal and exclusive jurisdiction of the courts located within '
        + 'the Republic of Lithuania, to resolve any dispute or claim arising from this Agreement. If you are a '
        + 'citizen of any European Union country or Switzerland, Norway, or Iceland, you shall be subject to the '
        + 'laws and courts of your habitual residence.',

        'Specifically excluded from application to this Agreement is that law known as the United Nations '
        + 'Convention on the International Sale of Goods.',
      ],
    },
    {
      title: 'Contact information',
      paragraphs: [
        'Questions about this End User License Agreement should be sent to us at address',
      ],
    },
  ];

  return (
    <StaticPageLayout>
      <MainHeaderClean title=" End-user license agreement" />
      <div className="container">
        { /* @ts-ignore */ }
        <StaticText content={content} withoutNumber />
      </div>
    </StaticPageLayout>
  );
};
