import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authUserLogout } from 'redusers/global/auth';
import { IFilterState, IFilterUpdatePayload } from './types';

const initialState: IFilterState = {
  isOpen: false,
  type: null,
  deviseSerials: [],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    filtersUpdate: (state, action: PayloadAction<IFilterUpdatePayload>) => {
      state.type = action.payload.type;
      state.deviseSerials = action.payload.deviseSerials;
    },
    filtersToggle: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authUserLogout.type, (state) => {
        state.isOpen = false;
        state.type = null;
        state.deviseSerials = [];
      });
  },
});

export const { filtersUpdate, filtersToggle } = filtersSlice.actions;
export const filtersReducer = filtersSlice.reducer;
