import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';

import API from 'api';
import reduxThunk from 'utils/reduxThunk';

import epics from './epics';
import reducerInit from './reducer';

const history = createBrowserHistory();
const reducer = reducerInit(history);

export const initStoreAndHistory = (() => {
  let data: any = null;

  return (initialState = {}) => {
    if (data) return data;

    const epicMiddleware = createEpicMiddleware<any, any, TRootState>({
      dependencies: API,
    });

    data = {
      store: createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(routerMiddleware(history), reduxThunk, epicMiddleware)),
      ),
      history,
    };

    epicMiddleware.run(epics);

    return data;
  };
})();

export type TRootState = ReturnType<typeof reducer>;
