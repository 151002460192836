import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import deleteOutline from '@iconify-icons/mdi/delete-outline';
import cheveronRight from '@iconify-icons/zondicons/cheveron-right';

import { openCtxModal } from 'redusers/ctxModal';
import { ModalTypes } from 'types/modal.types';
import { ISettingsDeleteComponentProps } from './types';

export const SettingsMenuDeleteComponent = ({ handleClick }: ISettingsDeleteComponentProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="settings-menu__item" onClick={handleClick}>
        <div className="settings-menu__item-type">
          <Icon icon={deleteOutline} className="settings-menu__item-icon" />
          <span className="settings-menu__item-name">{t('settings__delete-account')}</span>
        </div>
        <div className="settings-menu__item-meta">
          <Icon icon={cheveronRight} className="settings-menu__item-cheveron" />
        </div>
      </div>
    </Fragment>
  );
};

export const SettingsMenuDelete = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openCtxModal({
    modalType: ModalTypes.CTX_MODAL_SETTINGS_DELETE_ACCOUNT,
  }));

  return <SettingsMenuDeleteComponent handleClick={handleClick} />;
};
