import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Icon } from '@iconify/react';
import listIcon from '@iconify-icons/zondicons/list';
import tileIcon from '@iconify-icons/zondicons/view-tile';

import { selectIsList, updateIsListView } from 'redusers/global/view';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { ITableViewSwitchComponentProps, ITableViewSwitchProps } from './types';

export const TableViewSwitchComponent = ({ isList, className, setList, setTile }: ITableViewSwitchComponentProps) => {
  const tileIconClasses = classNames('sv-ui-btn-gray sv-ui-btn-m', {
    'sv-ui-btn-gray--active': !isList,
  });

  const listIconClasses = classNames('sv-ui-btn-gray sv-ui-btn-m', {
    'sv-ui-btn-gray--active': isList,
  });

  return (
    <div className={`d-flex ${className}`}>
      <div
        className={tileIconClasses}
        onClick={setTile}
        data-testid="tile-btn"
      >
        <Icon icon={tileIcon} />
      </div>
      <div
        className={listIconClasses}
        onClick={setList}
        data-testid="list-btn"
      >
        <Icon icon={listIcon} />
      </div>
    </div>
  );
};

export const TableViewSwitch = ({ className }: ITableViewSwitchProps) => {
  const dispatch = useDispatch();
  const isList = useSelector(selectIsList);

  const setList = () => {
    dispatch(updateIsListView(true));
    sendGtmTrigger('sv2_switch_view_to_table');
  };

  const setTile = () => {
    dispatch(updateIsListView(false));
    sendGtmTrigger('sv2_switch_view_to_tile');
  };

  const props = { className, isList, setList, setTile };
  return <TableViewSwitchComponent {...props} />;
};

export default TableViewSwitch;
