import React, { useRef, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import classNames from 'classnames';

import ContextMenu from 'components/common/ContextMenu';
import { Spinner, SpinnerType } from 'components/common/Spinner';
import { filesLoadModeStart, selectFiles, selectFilesHasMore, selectFilesLoading } from 'redusers/files/list';
import { selectCountSelectedFile, selectWithCtx } from 'redusers/files/selected';
import { SortPicker } from 'components/SortPicker';
import { selectIsList } from 'redusers/global/view';

import { MainFilesItem, MainFilesItemSkeleton } from '../MainFilesItem';

import './mainFiles.scss';
import { IMainFilesProps } from './types';

export const MainFilesComponent = ({
  isList,
  files,
  hasMore,
  withoutCtxBtn,
  filesLoading,
  loadFiles,
}: IMainFilesProps) => {
  const fileListRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const itemClasses = isList ? 'col-12' : 'col-6 col-md-4 col-lg-2';

  const elementClasses = classNames('main-files row custom-row', {
    'main-files--list': isList,
    'main-files--without-ctx-btn': withoutCtxBtn,
  });

  const renderFilesHeader = () => {
    if (isList) return null;

    return (
      <div className="main-screen__title-box">
        <div className="main-screen__section-title">{t('main__files')}</div>
        <SortPicker className="d-none d-md-block" />
      </div>
    );
  };

  if (filesLoading && !files.length) {
    return (
      <Fragment>
        {renderFilesHeader()}
        <div
          className={elementClasses}
        >
          {Array(24).fill('').map((file, idx) => (
            <MainFilesItemSkeleton
              key={idx}
              className={itemClasses}
              isList={isList}
            />
          ))}
        </div>
      </Fragment>

    );
  }

  return (
    <Fragment>
      {renderFilesHeader()}
      <InfiniteScroll
        loadMore={loadFiles}
        hasMore={hasMore}
        initialLoad={false}
        useWindow
        loader={<Spinner size={50} key="spinner_loader" />}
      >

        <div
          className={elementClasses}
          ref={fileListRef}
        >
          {files.map((file, idx) => {
            return filesLoading
              ? (
                <MainFilesItemSkeleton
                  key={idx}
                  className={itemClasses}
                  isList={isList}
                />
              )
              : (
                <MainFilesItem
                  key={file.id}
                  idx={idx}
                  className={itemClasses}
                  isList={isList}
                  file={file}
                />
              );
          })}
          {/* @ts-ignore */}
          <ContextMenu height={360} ref={fileListRef} />
        </div>
      </InfiniteScroll>
    </Fragment>
  );
};

export const MainFiles = () => {
  const dispatch = useDispatch();

  const isList = useSelector(selectIsList);
  const files = useSelector(selectFiles);
  const hasMore = useSelector(selectFilesHasMore);
  const filesLoading = useSelector(selectFilesLoading);
  const filesCount = useSelector(selectCountSelectedFile);
  const withCtx = useSelector(selectWithCtx);

  const loadFiles = useCallback(() => dispatch(filesLoadModeStart()), [dispatch]);

  const props = {
    loadFiles,
    isList,
    files,
    hasMore,
    filesLoading,
    withoutCtxBtn: !!filesCount && !withCtx,
  };

  return <MainFilesComponent {...props} />;
};
