import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import deleteOutline from '@iconify-icons/mdi/delete-outline';
import { fileDeleteStart } from 'redusers/files/list';
import { selectCtxModalData, selectCtxModalLoading, selectCtxModalIsMany } from 'redusers/ctxModal';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { IFileRes } from 'api/files.types';
import { IRemoveComponentProps } from './remove.types';

export const RemoveComponent = ({ removeHandler, loading, isMany }: IRemoveComponentProps) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isMany) return t('context-modal__file-remove-many');
    return t('context-modal__file-remove');
  };

  const renderDesc = () => {
    if (isMany) return t('context-modal__file-remove-desc-many');
    return t('context-modal__file-remove-desc');
  };

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={deleteOutline} className="custom-modal__icon" />
        <div className="custom-modal__title">

          {renderTitle()}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          {renderDesc()}
        </div>
        <div className="custom-modal__btn-box">
          <button
            onClick={removeHandler}
            className="btn btn-danger sv-btn custom-modal__btn-margin"
            disabled={loading}
          >
            {t('global__remove')}
          </button>
        </div>
      </Modal.Body>
    </Fragment>
  );
};

export const Remove = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const isMany = useSelector(selectCtxModalIsMany);
  const data = useSelector(selectCtxModalData) as IFileRes;

  const removeHandler = useCallback(() => {
    if (!isMany) {
      dispatch(fileDeleteStart(data.id));
      return sendGtmTrigger('sv2_file_delete');
    }
    dispatch(fileDeleteStart());
    return sendGtmTrigger('sv2_group_delete');
  }, [data, dispatch, isMany]);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Enter') removeHandler();
  }, [removeHandler]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  return <RemoveComponent removeHandler={removeHandler} loading={loading} isMany={isMany} />;
};
