import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { Icon } from '@iconify/react';
import accountMultipleOutline from '@iconify-icons/mdi/account-multiple-outline';
import linkVariant from '@iconify-icons/mdi/link-variant';

import { SocialBtns } from 'components/common/SocialBtns';
import toast from 'utils/toast';
import sendGtmTrigger from 'utils/sendGtmTrigger';
import { fileShareStart } from 'redusers/files/list';
import { closeCtxModal, selectCtxModalData, selectCtxModalLoading } from 'redusers/ctxModal';

import './fileShare.scss';
import { IFileShareComponentProps } from './fileShare.types';

export const FileShareComponent = ({ handleUpdateAccess, handleCopyLink, loading, shareUrl }: IFileShareComponentProps) => {
  const { t } = useTranslation();
  const [isShare, setIsShare] = useState(!!shareUrl);
  const updateAccess = () => handleUpdateAccess(!isShare);

  const copyLink = () => {
    handleCopyLink();
    toast(t('toast__link-copied'), { type: 'info' });
  };

  useEffect(() => {
    setIsShare(!!shareUrl);
  }, [shareUrl]);

  return (
    <Fragment>
      <Modal.Header closeButton>
        <Icon icon={accountMultipleOutline} className="custom-modal__icon" />
        <div className="custom-modal__title">{t('context-modal__file-share')}</div>
      </Modal.Header>
      <Modal.Body className="file-share-modal">
        <div className="form-group form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="share-checkbox"
            onChange={updateAccess}
            checked={isShare}
          />
          <label className="form-check-label" htmlFor="share-checkbox">
            {t('form__file-share-checkbox-label')}
          </label>
        </div>
        <div className="file-share-modal__link-box">
          <Icon icon={linkVariant} className="file-share-modal__link-icon" />
          <span>
            {loading ? t('global__loading') : shareUrl || t('form__file-share-no-link')}
          </span>
        </div>
        {
          shareUrl
            ? <SocialBtns url={shareUrl} className="mt-3" />
            : null
        }
        <div className="custom-modal__btn-box">
          <button
            onClick={copyLink}
            className="btn sv-btn-blue sv-btn custom-modal__btn-margin"
            disabled={loading || !isShare}
          >
            {t('form__file-share-copy')}
          </button>
        </div>
      </Modal.Body>
    </Fragment>
  );
};

export const FileShare = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectCtxModalLoading);
  const data = useSelector(selectCtxModalData);

  if (!data) return null;

  const handleUpdateAccess = (isOpen: boolean) => {
    dispatch(fileShareStart({ data, isOpen }));
    sendGtmTrigger('sv2_file_share');
  };

  const handleCopyLink = () => {
    copy(data.share_url as string);
    dispatch(closeCtxModal());
    sendGtmTrigger('sv2_file_copy_link');
  };

  const props = {
    loading,
    handleUpdateAccess,
    handleCopyLink,
    shareUrl: data.share_url,
  };

  return <FileShareComponent {...props} />;
};
