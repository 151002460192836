import { PayloadAction } from '@reduxjs/toolkit';
import { Epic, ofType } from 'redux-observable';
import { goBack } from 'connected-react-router';
import { from, of, concat } from 'rxjs';
import { exhaustMap, concatMap, catchError, map } from 'rxjs/operators';
import { TRootState } from 'redusers';
import API from 'api';
import { getPagination } from 'utils/getPagination';
import { closeCtxModal, updateCtxModalLoading, updateCtxModalData } from 'redusers/ctxModal';
import { fileModalOpenFromSearchUpdate, fileModalIndexDecrease } from 'redusers/fileModal';
import { selectFileClear } from 'redusers/files/selected';
import {
  filesLoadModeStart,
  filesFetchingStart,
  filesLoadModeFailed,
  filesFetchingFailed,
  filesLoadModeSuccess,
  filesFetchingSuccess,
  fileUpdateStart,
  fileUpdateSuccess,
  fileDeleteStart,
  fileDeleteSuccess,
  fileShareStart,
} from './fileList.slice';
import { IUpdateFileValues } from '../../../api/files.types';
import { searchDeleteItemByIds } from '../../search';

export const getFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { getFiles },
) => action$.pipe(
  ofType(filesFetchingStart, filesLoadModeStart),
  map(({ type }) => {
    const { sort, filters, files, search } = state$.value;
    return {
      apiParams: {
        sortFieldName: sort.fieldName,
        sortAsc: sort.asc,
        fileType: filters.type,
        filterDeviseSerials: filters.deviseSerials,
        searchStr: search.detectedString,
        currentPage: type === filesFetchingStart.type ? 0 : files.list.pagination.currentPage,
      },
      successAction: type === filesFetchingStart.type ? filesFetchingSuccess : filesLoadModeSuccess,
      failedAction: type === filesFetchingStart.type ? filesFetchingFailed : filesLoadModeFailed,
    };
  }),
  exhaustMap(({ apiParams, successAction, failedAction }) => {
    return from(getFiles(apiParams)).pipe(
      map(({ data, status, headers }) => {
        if (status !== 200) return failedAction();
        return successAction({ data, pagination: getPagination(headers) });
      }),
      catchError(() => of(failedAction())),
    );
  }),
);

export const updateFileEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { updateFile },
) => action$.pipe(
  ofType(fileUpdateStart),
  exhaustMap<PayloadAction<{ id: string, values: IUpdateFileValues }>, any>(({ payload }) => {
    const apiRequest$ = from(updateFile(payload.id, payload.values)).pipe(
      map(({ data, status }) => {
        const { ctxModal: { fromSearch } } = state$.value;
        if (status === 200) {
          const actions = [fileUpdateSuccess(data), closeCtxModal()];
          if (fromSearch) actions.push(fileModalOpenFromSearchUpdate(data));
          return actions;
        }
        return updateCtxModalLoading(false);
      }),
    );

    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

// если передан id удаляем его, если не передан достаем ид из выделенных файлов
export const deleteFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { deleteFile },
) => action$.pipe(
  ofType(fileDeleteStart),
  exhaustMap(({ payload }) => {
    let idArray: string[] = [];
    if (payload) {
      idArray = [payload];
    } else {
      const selectedIndexes = state$.value.files.selected.indexes;
      const files = state$.value.files.list.data;

      idArray = selectedIndexes.map((idx) => files[idx].id);
    }

    const apiRequest$ = from(deleteFile(idArray)).pipe(
      map(({ status }) => {
        const { ctxModal: { fromSearch }, fileModal, files: { list: { data: fileData } } } = state$.value;
        if (status === 204) {
          const actions: { type: string, payload?: any }[] = [
            selectFileClear(),
            fileDeleteSuccess(idArray),
          ];

          if (fileModal.isOpen
            && !fileModal.fromSearch
            && fileModal.fileIdx !== null
            && fileModal.fileIdx >= fileData.length - 1
          ) {
            actions.push(fileModalIndexDecrease());
          }

          if (fromSearch) {
            actions.push(goBack());
            actions.push(searchDeleteItemByIds(idArray));
          }

          actions.push(closeCtxModal());
          return actions;
        }

        return updateCtxModalLoading(false);
      }),
    );
    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const shareFilesEpic: Epic<any, any, TRootState, typeof API> = (
  action$, state$, { shareFile },
) => action$.pipe(
  ofType(fileShareStart),
  exhaustMap(({ payload }) => {
    const apiRequest$ = from(shareFile(payload.data.id, payload.isOpen)).pipe(
      map(({ data, status }) => {
        const { ctxModal: { fromSearch } } = state$.value;
        if (status === 200 || status === 204) {
          let newFile = payload.data;
          if (payload.isOpen) newFile = { ...newFile, share_url: data.share_url };
          else {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { share_url, ...otherProps } = newFile;
            newFile = otherProps;
          }

          const actions = [updateCtxModalData(newFile), fileUpdateSuccess(newFile), updateCtxModalLoading(false)];

          if (fromSearch) actions.push(fileModalOpenFromSearchUpdate(newFile));

          return actions;
        }

        return updateCtxModalLoading(false);
      }),
    );
    return concat(of(updateCtxModalLoading(true)), apiRequest$);
  }),
  concatMap((action) => ((Array.isArray(action)) ? from(action) : of(action))),
);

export const fileListEpics = [getFilesEpic, deleteFilesEpic, updateFileEpic, shareFilesEpic];
