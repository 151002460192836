import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '@iconify/react';
import filterOutline from '@iconify-icons/mdi/filter-outline';

import sendGtmTrigger from 'utils/sendGtmTrigger';
import { devicesFetchingStart } from 'redusers/global/devices';
import { selectFileClear } from 'redusers/files/selected';
import {
  selectFilterDeviceSerials,
  selectFilterType,
  selectFilterIsOpen,
  selectFilterCount,
  filtersUpdate,
  filtersToggle,
} from 'redusers/filters';
import { FileTypes } from 'types/file.types';

import { FilterType } from './filterType';
import { FilterDevice } from './filterDevice';
import { DropDownModal } from '../common/DropDownModal';

import './index.scss';
import {
  IFilterPickerComponentProps,
  IFilterPickerProps,
  THandleToggle,
  THandleSaveFilter,
  TSetType,
  TSetDeviseSerials,
} from './types';

export const FilterPickerComponent = ({
  handleSaveFilter,
  handleSelectClear,
  handleToggle,
  type,
  deviseSerials,
  count,
  className,
  isOpen,
}: IFilterPickerComponentProps) => {
  const { t } = useTranslation();

  const [filterChanged, setFilterChanged] = useState<boolean>(false);
  const [newType, setNewType] = useState<FileTypes | null>(null);
  const [newDevicesSerials, setNewDevicesSerials] = useState<string[]>([]);

  const handlerSetType: TSetType = (value) => {
    setNewType(value);
    setFilterChanged(true);
  };

  const handlerSetDeviseSerial: TSetDeviseSerials = (value) => {
    const serials = [...newDevicesSerials];
    const currIdx = serials.findIndex((serial) => serial === value);

    if (currIdx < 0) serials.push(value);
    else serials.splice(currIdx, 1);

    setNewDevicesSerials(serials);
    setFilterChanged(true);
  };

  const clearDeviseSerials = () => {
    setNewDevicesSerials([]);
    setFilterChanged(true);
  };

  const handleBeforeOpen = () => {
    handleSelectClear();
    setNewType(type);
    setNewDevicesSerials(deviseSerials);
    setFilterChanged(false);
  };

  const handleAfterClose = () => {
    setNewDevicesSerials([]);
    setNewType(null);
    setFilterChanged(false);
  };

  const renderBtn = useMemo(() => {
    const desktopBtnClasses = classNames(
      'sv-ui-btn-gray sv-ui-btn-m  filter-picker__btn d-none d-md-flex',
      { 'sv-ui-btn-gray--active': isOpen },
    );

    return (
      <Fragment>
        <div className={desktopBtnClasses}>
          <Icon icon={filterOutline} />
          {count ? (<span className="badge badge-primary">{count}</span>) : null}
        </div>
        <div className="filter-picker__m-btn d-block d-md-none">
          {t('filter__header-title')}
          {count ? (<span className="badge badge-primary">{count}</span>) : null}
        </div>
      </Fragment>

    );
  }, [count, isOpen, t]);

  const renderFooter = (toggle: () => void) => {
    const saveFilter = () => {
      toggle();
      handleSaveFilter(newType, newDevicesSerials);
      sendGtmTrigger('sv2_filter_use');
    };

    return (
      <button
        className="btn sv-btn-blue sv-btn filter-picker__footer-btn"
        onClick={saveFilter}
        disabled={!filterChanged}
      >
        {t('filter__btn-text')}
      </button>
    );
  };

  const footerClasses = classNames('filter-picker__footer', {
    'filter-picker__footer--changed': filterChanged,
  });

  return (
    <DropDownModal
      className={`filter-picker ${className}`}
      renderButton={renderBtn}
      renderFooter={renderFooter}
      afterCloseFn={handleAfterClose}
      beforeOpenFn={handleBeforeOpen}
      mobileHeaderTitle={t('filter__header-title')}
      footerClassName={footerClasses}
      customToggle={handleToggle}
      customIsOpen={isOpen}
    >
      <FilterType setType={handlerSetType} currentType={newType} />
      <FilterDevice
        currentDevicesSerials={newDevicesSerials}
        clearDeviseSerials={clearDeviseSerials}
        setDeviseSerials={handlerSetDeviseSerial}
      />
    </DropDownModal>
  );
};

export const FilterPicker = ({ className }: IFilterPickerProps) => {
  const dispatch = useDispatch();
  const type = useSelector(selectFilterType);
  const deviseSerials = useSelector(selectFilterDeviceSerials);
  const isOpen = useSelector(selectFilterIsOpen);
  const count = useSelector(selectFilterCount);

  const handleSaveFilter: THandleSaveFilter = (newType, newDevicesSerials) => {
    dispatch(filtersUpdate({ type: newType, deviseSerials: newDevicesSerials }));
  };

  const handleSelectClear = () => dispatch(selectFileClear());
  const handleToggle: THandleToggle = (value) => dispatch(filtersToggle(value));

  const props = {
    className,
    type,
    deviseSerials,
    isOpen,
    count,
    handleSaveFilter,
    handleSelectClear,
    handleToggle,
  };

  useEffect(() => {
    dispatch(devicesFetchingStart());
  }, [dispatch]);

  return <FilterPickerComponent {...props} />;
};
