import React from 'react';
import { Link } from 'react-router-dom';
import { ITopHeaderLogoWrapProps, ITopHeaderLogoProps } from './types';

const TopHeaderLogoWrap = ({ children, logoWithoutLink }: ITopHeaderLogoWrapProps) => {
  if (logoWithoutLink) {
    return <div className="top-header__logo-box">{children}</div>;
  }

  return <Link to="/" className="top-header__logo-box">{children}</Link>;
};

export const TopHeaderLogo = ({ logoWithoutLink } : ITopHeaderLogoProps) => {
  return (
    <TopHeaderLogoWrap logoWithoutLink={logoWithoutLink}>
      <img className="top-header__black-logo" src="/static/logo.png" alt="" />
      <img className="top-header__white-logo" src="/static/logo_white.png" alt="" />
    </TopHeaderLogoWrap>
  );
};
